import React, {useContext, useEffect, useState} from "react";
import { connect } from "react-redux";
import {
    Grid,
    makeStyles,
    TableContainer, TableHead, TableRow,
    Typography,
} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import { ActionContext } from "../../contexts";
import CircularProgress from "modules/shared/CircularProgress";
import {Container, DropDown, HeaderButton, SearchBox, Title} from "../shared";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { accountTypes } from '../../store/constants';

let useStyles = makeStyles((theme) => ({
    flexALignDataflow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        paddingBottom: '1rem',
    },
    rowDataAlignment: {
        display: 'block',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    noAccounts: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1rem',
        color: theme.palette.error.main,
        fontFamily: theme.fontFamily.prometo,
        fontWeight: 700,
        fontSize: '1.1rem',
    },
    tableHead: {
        borderBottom: 'solid 1px #f2f2f2',
        backgroundColor: theme.palette.common.white,
        marginBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0.8rem 0.5rem',
        width: '100%',
    },
    rowAlignment: {
        border: 'solid 1px #f2f2f2',
        borderRadius: 8,
        backgroundColor: theme.palette.common.white,
        marginBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0rem 0.5rem'
    },
    fullName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '1.2em',
        whiteSpace: 'nowrap',
        fontWeight: '700',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
        textAlign: 'center',
    },
    email: {
        fontWeight: '300',
        display: 'flex',
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: 14,
        justifyContent: 'center',
    },
    dataTableAlignment: {
        fontWeight: '300',
        display: 'flex',
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: 14,
        justifyContent: 'center',
        textTransform: 'capitalize',
    },
    link: {
        textDecoration: "none",
        fontWeight: '700',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.text.secondary,
            textDecoration: "underline",
        },
    },
    iconColor: {
      color: theme.palette.text.primary,
    },
}));

const AccountList = (props) => {
    let classes = useStyles();
    let history = useHistory();
    let { accountType, accountListDetails, teams, updateFilter, loadingAccounts } = props;
    let { accountActions, dashboardActions, playerActions } = useContext(ActionContext);
    const [accountsFilteredByTeam, setAccountsFilteredByTeam] = useState([]);

    useEffect(() => {
        dashboardActions.getAccountsList();
        playerActions.getTeamList();
    }, []);

    const handleCreateAccount = () => {
        history.push("/create-user");
    };

    function handleSearchText(v) {
        dashboardActions.selectAccountFilter("searchText", v);
    }

    const handleTeamChange = (event) => {
        dashboardActions.selectAccountFilter("searchTeam", event.target.value);
        let team: event.target.value;
        let findAccounts = accountListDetails.filter(account => account.team === team);
        setAccountsFilteredByTeam(findAccounts);
    };

    function handleViewAllChange() {
        dashboardActions.selectAccountFilter("searchText", undefined);
        dashboardActions.selectAccountFilter("searchTeam", undefined);
        dashboardActions.getAccountsList();
    }

    function canEdit(account_type) {
        if (['Mental Metrix Admin', 'Super Admin'].indexOf(account_type) !== -1 && accountType === accountTypes.mmDdiAdmin ) return false;
        return !(account_type === accountTypes.superAdmin && accountType === accountTypes.superAdmin);
    }

    const handleUpdatePage = (user_id) => {
        accountActions.selectedAccountById(user_id);
        history.push("/update-user");
    };

    const filteredSearchData = updateFilter?.searchText ? accountListDetails?.filter(v => v.full_name.toLowerCase().includes(updateFilter.searchText.toLowerCase())) : accountListDetails && accountListDetails;
    const filteredAccountsData = updateFilter?.searchTeam ? filteredSearchData?.filter(v => v.team?.toLowerCase().includes(updateFilter.searchTeam.toLowerCase())) : filteredSearchData && filteredSearchData;
    const filterAll = accountsFilteredByTeam.length > 0 ? accountsFilteredByTeam : filteredAccountsData;

    return (
        <Container >
            <div className={classes.flexALignDataflow}>
                <Title title='Accounts List' />
                <div style={{display: 'flex'}}>
                    <HeaderButton
                        onclick={handleCreateAccount}
                        title='Create Account'
                    />
                    <HeaderButton
                        onclick={handleViewAllChange}
                        title='View All'
                    />
                    <SearchBox
                        placeholder="Search Account Name"
                        value={updateFilter?.searchText !== undefined ? updateFilter?.searchText : ""}
                        onChange={ev => handleSearchText(ev.target.value)}
                    />
                    <DropDown
                        name="team"
                        value={updateFilter?.searchTeam !== undefined ? updateFilter?.searchTeam : ""}
                        onChange={handleTeamChange}
                        placeValue='Team'
                        data={teams}
                    />
                </div>
            </div>
            <TableContainer>
                <div className={classes.rowDataAlignment}>
                    <Grid style={{ padding: '1rem 0px' }}>
                        {loadingAccounts && loadingAccounts ?
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress size={72} thickness={3} color="primary"/>
                            </div>
                            :
                            (filterAll && filterAll.length === 0) ?
                                <Typography className={classes.noAccounts}>
                                    No Accounts found
                                </Typography> : (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TableHead className={classes.tableHead}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        className={classes.fullName}
                                                        style={{ textAlign: 'start'}}

                                                    >
                                                        name
                                                    </Grid>
                                                    <Grid item xs={3} className={classes.fullName}>email</Grid>
                                                    <Grid item xs={2} className={classes.fullName}>team</Grid>
                                                    <Grid item xs={2} className={classes.fullName}>role</Grid>
                                                    <Grid item xs={2} className={classes.fullName}>status</Grid>
                                                    <Grid item xs={1} className={classes.fullName}>edit</Grid>
                                                </Grid>
                                            </TableHead>
                                            {
                                                filterAll && filterAll.map((result) => (
                                                    <TableRow className={classes.rowAlignment}>
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            className={classes.fullName}
                                                            style={{ textAlign: 'start' }}
                                                        >
                                                            {result.full_name}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            className={classes.email}
                                                        >
                                                            {result.email}
                                                        </Grid>
                                                        <Grid item xs={2} className={classes.dataTableAlignment}>
                                                            {result.team}
                                                        </Grid>
                                                        <Grid item xs={2} className={classes.dataTableAlignment}>
                                                            {result.account_type}
                                                        </Grid>
                                                        <Grid item xs={2} className={classes.dataTableAlignment}>
                                                            {result.status}
                                                        </Grid>
                                                        <Grid item xs={1} className={classes.dataTableAlignment}>
                                                            {canEdit(result.account_type) ? (
                                                                <div onClick={() => handleUpdatePage(result._id)}>
                                                                    <CreateOutlinedIcon className={classes.iconColor}/>
                                                                </div>
                                                            ) : null}
                                                        </Grid>
                                                    </TableRow>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                )
                        }
                    </Grid>
                </div>
            </TableContainer>
        </Container>
    );
}

let mapStateToProps = (state) => {
    return {
        accountListDetails: state.dashboard.accountListReducer,
        accountType: state.account.accountType,
        teams: state.ui.player.allCandidateTeams,
        userTeam: state.account.team,
        updateFilter: state.dashboard.updateAccountFilter,
        loadingAccounts: state.ui.dashboard.loadingAccounts,
    };
};

export default connect(mapStateToProps)(AccountList);