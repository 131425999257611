import apiHandler, {getAuthHeader} from "./apiHandler";

export const getCommunicationDataByProfileId = async (profileId) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.get(`/communications_data_by_id/${profileId}`, {headers});

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.communications;
    } catch (err) {
        throw new Error(err);
    }
};

const receptiviti = {
    getCommunicationDataByProfileId,
}

export default receptiviti;