import { Box, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { round } from "../../../utils";
import Profile from "../../../assets/slider-user-icon.svg";

const LinearGradientBar = ({ gradientColors = [], score }) => {
    const defaultColors = ['#002839', '#FFB71B', '#0070B9', '#058AE0', '#8EC158'];
    const colors = gradientColors.length > 0 ? gradientColors : defaultColors;
    const useStyles = makeStyles(theme => ({
        root: {
            display: "flex",
            width: "100%",
            flexGrow: 1,
            padding: '1rem',
            marginBottom: '0.5rem',
        },
        linearGraph: {
            position: 'relative',
            width: '100%',
            height: '20px',
            background: `linear-gradient(to right, ${colors})`,
            borderRadius: '1rem',
            boxShadow: '0px 0px 0.2rem 0.1rem rgba(0, 0, 0, 0.2)',
            margin: '0px 1rem',
        },
        label: {
            position: 'absolute',
            transform: 'translateX(-50%)',
            textTransform: 'uppercase',
            backgroundColor: theme.palette.text.primary,
            color: 'white',
            padding: '0.2rem',
            borderRadius: '0.2rem',
            width: 30,
            height: 30,
            top: '-5px',
        },
        highLow: {
            fontFamily: theme.fontFamily.robotoCondensed,
            textAlign: 'center'
        },
    }));
    const classes = useStyles();

    let roundTo1PrecisionPoint = (value) => {
        let roundedValue = round(value, 0);
        return Math.min(100, Math.max(0, roundedValue));
    };

    const pointerPosition = roundTo1PrecisionPoint(score);

    return (
        <div className={classes.root}>
            <Typography className={classes.highLow}>Low</Typography>
            <Box className={classes.linearGraph}>
                <Box className={classes.label} style={{ left: `${pointerPosition}%` }}>
                    <img src={Profile} alt="Profile" />
                </Box>
            </Box>
            <Typography className={classes.highLow}>High</Typography>
        </div>
    )
};

export default LinearGradientBar;
