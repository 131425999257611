import React, {useContext, useEffect} from "react";
import { Grid, FormControl, FormControlLabel, FormLabel, Box, Radio, RadioGroup, FormHelperText, InputLabel, MenuItem } from "@material-ui/core";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import MuiTextField from "@material-ui/core/TextField";
import Button from "../shared/Button";
import Alert from "@material-ui/lab/Alert";
import { ActionContext } from "../../contexts";
import { object, string } from "yup";
import { connect } from "react-redux";
import { Field, Formik, Form } from "formik";
import { Autocomplete } from "formik-material-ui-lab";
import { TextField, Select } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import {GlobalCss} from "../../config/GlobalCss";
import {Container, RunDashedLogo, Title} from "../shared";
import { accountTypes } from '../../store/constants';

let useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    padding: '1rem 0px',
  },
  label: {
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  errorMsg: {
    color: theme.palette.error.main,
  },
  content: {
    display: "flex",
    justifyContent: "end",
    margin: '2rem 0rem 0rem 0rem'
  },
  btnPrimary: {
    color: theme.palette.common.white,
    padding: '10px',
    backgroundColor: theme.colors.primaryNavy,
    width: '13rem',
    borderRadius: '7px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.colors.primaryNavy,
      border: `1px solid ${theme.colors.primaryNavy}`,
    },
    textTransform: 'uppercase',
  },
  btnSecondary: {
    color: theme.palette.secondary.main,
    border: '1px solid',
    width: '13rem',
    padding: '10px',
    marginRight: '10px',
    borderRadius: '7px',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    textTransform: 'uppercase',
  },
}));

const CreateAccount = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { teams, className, accountTypeNames, creatingAccount, error, conferenceByType, teamByConference, accountType } = props;
  const { accountActions, snackbarActions, conferenceActions, sportsActions } = useContext(ActionContext);


  useEffect(() => {
    accountActions.getUserTypes();
  }, []);

  const conferenceTypes = {
    "Professional": "Professional",
    "Collegiate": "Collegiate"
  }

  const [accountFor, setAccountFor] = React.useState('')

  let organizationTeam = teamByConference && teamByConference.reduce((obj, item) => {
    obj[item.name] = item.name;
    return obj;
  }, {});

  let finalTeams = JSON.parse(JSON.stringify(organizationTeam));

  let organizationConference = conferenceByType && conferenceByType.reduce((obj, item) => {
    obj[item.name] = item.name;
    return obj;
  }, {});

  let finalConferenceByType = JSON.parse(JSON.stringify(organizationConference));

  let handleFormSubmit = (values, helpers) => {
    let data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: "",
      team: values.team,
      account_type: values.accountType,
    };

    let handleSuccess = () => {
      helpers.setSubmitting(false);
      helpers.resetForm();
      snackbarActions.showSnackbar(
        "Confirmation email has been sent with account details"
      );
      history.push("/account-management");
    };

    let handleFail = () => {
      helpers.setSubmitting(false);
    };

    accountActions.signUp(data, handleSuccess, handleFail);
  };

  let defaultTeam =
    Object.keys(teams).length > 1 ? null : Object.keys(teams)[0];

  let handleErrorClose = () => {
    accountActions.resetAccountCreationError();
  };

  useEffect(() => {
    return () => {
      accountActions.resetAccountCreationError();
    };
  }, []);

  useEffect(() => {
    if (accountType !== undefined) {
      if (accountType === accountTypes.mmDdiAdmin) {
        setAccountFor('client')
      } else {
        setAccountFor('mmAdmin')
      }

    } else {
      setAccountFor('mmAdmin')
    }
  }, [accountType])

  useEffect(() => {
    if (error && error.length > 0) {
      setTimeout(() => {
        handleErrorClose();
      }, 3500);
    }
  }, [error]);

  const handleClose = () => {
    history.push("/account-management");
  }

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <div style={{ margin: '0rem 3rem' }}>
            <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  team: defaultTeam,
                  accountType: "",
                  conference_type: "",
                  conference: ""
                }}
                validationSchema={object().shape({
                  firstName: string()
                      .trim()
                      .matches(
                          /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                          "Enter valid First name"
                      )
                      .required("Enter player first ame"),
                  lastName: string()
                      .trim()
                      .matches(
                          /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                          "Enter valid Last name"
                      )
                      .required("Enter player last name"),
                  team: string().nullable().required("Select team"),
                  email: string().email("Enter a valid email").required("Enter email"),
                  accountType: string().nullable().required("Select Role"),
                })}
                onSubmit={handleFormSubmit}
            >
              {(formik) => {
                let { errors, touched, values } = formik;

                const handleChange = (value) => {
                  values.conference_type = value;
                  values.conference = "";
                  values.team = "";
                  conferenceActions.getConferenceByType(value);
                }

                const handleChangeTeam = (value) => {
                  values.team = value;
                  sportsActions.getTeamUsersList(value)
                }

                const handleChangeConf = (value) => {
                  values.conference = value;
                  let conference = conferenceByType.find(con => con.name === value);

                  if (conference) {
                    conferenceActions.getTeamsByConferenceId(conference._id);
                  }
                }

                const handleChangeAccountFor = (event) => {
                  values.conference_type = "";
                  values.conference = "";
                  values.team = "";
                  values.sport = "";
                  values.accountType = "";
                  setAccountFor(event.target.value)
                }

                return (
                    <Form autoComplete="off">
                      <div className={classes.form}>
                        <Title title='Create Account' />
                        <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                          <GlobalCss />
                          {error ? (
                              <Grid item>
                                <Alert severity="error" onClose={handleErrorClose}>
                                  {error}
                                </Alert>
                              </Grid>
                          ) : null}
                          <Grid item lg={6} sm={12}>
                            <Field
                                name="firstName"
                                label="First Name"
                                placeholder="First Name"
                                component={TextField}
                                variant="outlined"
                                required
                                fullWidth
                                autoFocus
                            />
                          </Grid>
                          <Grid item lg={6} sm={12}>
                            <Field
                                name="lastName"
                                label="Last Name"
                                placeholder="Last Name"
                                component={TextField}
                                variant="outlined"
                                fullWidth
                                required
                            />
                          </Grid>
                          <Grid item md={12} sm={12}>
                            <Field
                                name="email"
                                label="Email"
                                placeholder="Email"
                                component={TextField}
                                variant="outlined"
                                fullWidth
                                required
                            />
                          </Grid>
                          <Grid item md={12} sm={12}>
                            {
                              (accountType === "SUPER_ADMIN") ?
                                  <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <Box sx={{ display: 'flex' }}>
                                      <FormControl className={classes.label}>
                                        <FormLabel id="demo-radio-buttons-group-label" >Account For</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue=""
                                            row
                                            name="accountFor"
                                            value={accountFor}
                                            onChange={handleChangeAccountFor}
                                        >
                                          <FormControlLabel value="mmAdmin" control={<Radio />} label="Mental Metrix" />
                                          <FormControlLabel value="client" control={<Radio />} label="Clients" />
                                        </RadioGroup>
                                      </FormControl>
                                    </Box>
                                  </div>
                                  :
                                  ""
                            }
                          </Grid>
                          {
                            accountFor === "mmAdmin" ? "" :
                                <Grid item lg={6} sm={12}>
                                  <Field
                                      name="conference_type"
                                      component={Autocomplete}
                                      options={Object.keys(conferenceTypes)}
                                      renderInput={(props) => {
                                        return (
                                            <MuiTextField
                                                variant="outlined"
                                                label="League"
                                                placeholder="League"
                                                error={touched["conference_type"] && !!errors["conference_type"]}
                                                helperText={errors["conference_type"]}
                                                required
                                                {...props}
                                            />
                                        );
                                      }}
                                      onChange={(e, value) => {
                                        handleChange(value)
                                      }}
                                      renderOption={(value, { inputValue }) => {
                                        const matches = match(value, inputValue);
                                        const parts = parse(value, matches);

                                        return (
                                            <div>
                                              {parts.map((part, index) => (
                                                  <span
                                                      key={index}
                                                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                                                  >
                                              {part.text}
                                            </span>
                                              ))}
                                            </div>
                                        );
                                      }}
                                  />
                                </Grid>
                          }
                          {
                            accountFor === "mmAdmin" ? "" :
                                <Grid item md={12} sm={12} lg={6}>
                                  <Field
                                      name="conference *"
                                      component={Autocomplete}
                                      options={Object.keys(finalConferenceByType)}
                                      required
                                      renderInput={(props) => {
                                        return (
                                            <MuiTextField
                                                variant="outlined"
                                                label="Conference"
                                                placeholder="Conference"
                                                error={touched["conference"] && !!errors["conference"]}
                                                helperText={errors["conference"]}
                                                required
                                                {...props}
                                            />
                                        );
                                      }}
                                      onChange={(e, value) => {
                                        handleChangeConf(value)
                                      }}

                                      renderOption={(value, { inputValue }) => {
                                        const matches = match(value, inputValue);
                                        const parts = parse(value, matches);

                                        return (
                                            <div>
                                              {parts.map((part, index) => (
                                                  <span
                                                      key={index}
                                                      style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                      }}
                                                  >
                                              {part.text}
                                            </span>
                                              ))}
                                            </div>
                                        );
                                      }}
                                  />
                                </Grid>
                          }

                          <Grid item md={12} sm={12} lg={6}>
                            <Field
                                name="team"
                                component={Autocomplete}
                                options={accountFor === "mmAdmin" ? Object.keys(teams).filter(v => v === "MM-DDI") : Object.keys(finalTeams)}
                                required
                                renderInput={(props) => {
                                  return (
                                      <MuiTextField
                                          variant="outlined"
                                          label="Select Team"
                                          placeholder="Team"
                                          error={touched["team"] && !!errors["team"]}
                                          helperText={<FormHelperText className={classes.errorMsg}>{errors["team"]}</FormHelperText>}
                                          required
                                          {...props}
                                      />
                                  );
                                }}
                                onChange={(e, value) => { handleChangeTeam(value) }}
                                renderOption={(value, { inputValue }) => {
                                  const matches = match(value, inputValue);
                                  const parts = parse(value, matches);

                                  return (
                                      <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                  fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                          {part.text}
                                        </span>
                                        ))}
                                      </div>
                                  );
                                }}
                            />
                          </Grid>
                          <Grid item md={12} sm={12} lg={6}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                error={touched["accountType"] && !!errors["accountType"]}
                            >
                              <InputLabel htmlFor="accountTypeLabel">
                                Role *
                              </InputLabel>
                              <Field
                                  component={Select}
                                  name="accountType"
                                  label="Role *"
                                  placeholder="Role *"
                                  labelId="accountTypeLabel"
                                  inputProps={{
                                    id: "accountType",
                                  }}
                                  required
                              >
                                {Object.keys(accountTypeNames)
                                    .filter((key) => {
                                      return !(values.team === "MM-DDI" &&
                                          accountTypeNames[key]["code"] ===
                                          accountTypeNames["ORG_ADMIN"]["code"]);

                                    })
                                    .filter((key) => {
                                      if (accountFor === "mmAdmin" && (key.slice(0, 2) !== "MM") && (key.slice(0, 3) !== "ORG")) {
                                        return false
                                      }
                                      else if (values?.conference_type === "Professional" && (key.slice(0, 3) !== "PRO")) {
                                        return false;
                                      } else return !(values?.conference_type === "Collegiate" && (key.slice(0, 3) !== "COL"));
                                    })
                                    .map((key, index) => {
                                      return (
                                          <MenuItem
                                              key={index}
                                              value={accountTypeNames[key]["code"]}
                                          >
                                            {accountTypeNames[key]["name"]}
                                          </MenuItem>
                                      );
                                    })}
                              </Field>
                              {touched["accountType"] && !!errors["accountType"] ? (
                                  <FormHelperText className={classes.errorMsg}>{errors["accountType"]}</FormHelperText>
                              ) : null}
                            </FormControl>
                          </Grid>
                        </Grid>
                        <div className={clsx(classes.content, className)}>
                          <Button
                              className={classes.btnSecondary}
                              type="button"
                              variant="text"
                              onClick={handleClose}
                          >
                            Cancel
                          </Button>&nbsp;
                          <Button
                              className={classes.btnPrimary}
                              type="submit"
                              loading={creatingAccount}
                          >
                            Create
                          </Button>
                        </div>
                      </div>
                    </Form>
                );
              }}
            </Formik>
          </div>
        </Grid>
        <Grid item xs={4}>
          <RunDashedLogo />
        </Grid>
      </Grid>
    </Container>
  );
};

let mapStateToProps = (state) => {
  return {
    teams: state.ui.player.organizationTeams,
    accountTypeNames: state.ui.account.userTypes,
    creatingAccount: state.ui.account.register.loading,
    error: state.ui.account.register.error,
    conferenceByType: state.conference.allConferenceByType,
    teamByConference: state.conference.teamByConference,
    accountType: state.account.accountType,
    userTypes: state.sports.userTypesList,
  };
};

export default connect(mapStateToProps)(CreateAccount);
