import apiHandler, {getAuthHeader} from "./apiHandler";
import {parseFeedResponse} from "./responseParser";

export const getPlayers = async () => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get("/load-candidates", { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.players.reduce((obj, item) => {
      obj[item._id] = {
        id: item._id,
        name: item.full_name,
        isHighRisk: item.risk === "high" ?? false,
        risk: item.risk,
        birthday: item.dob,
        team: item.team,
        image: item.image_url,
        consent: item.consent,
        consentFormId: item["consent_form_id"],
        status: item.status,
      };
      return obj;
    }, {});
  } catch (err) {
    throw new Error(err);
  }
};

export const getCandidates = async () => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get("/load-candidates", { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.players;
  } catch (err) {
    throw new Error(err);
  }
};

export const getPlayerFeed = async (playerId, source) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/feed/${playerId}/${source}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    const negativeArr = response.data.info.negatives;
    const negatives = parseFeedResponse(negativeArr);

    const neutralsArr = response.data.info.neutrals;
    const neutrals = parseFeedResponse(neutralsArr);

    const positivesArr = response.data.info.positives;
    const positives = parseFeedResponse(positivesArr);

    const data = { ...negatives, ...neutrals, ...positives };

    return {
      negatives: Object.keys(negatives),
      positives: Object.keys(positives),
      neutrals: Object.keys(neutrals),
      data,
      graphs: response.data.info.graphs,
    };
  } catch (err) {
    throw new Error(err);
  }
};

export const getCoachFeed = async (coachId, source) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/feed/coach/${coachId}/${source}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    const negativeArr = response.data.info.negatives;
    const negatives = parseFeedResponse(negativeArr);

    const neutralsArr = response.data.info.neutrals;
    const neutrals = parseFeedResponse(neutralsArr);

    const positivesArr = response.data.info.positives;
    const positives = parseFeedResponse(positivesArr);

    const data = { ...negatives, ...neutrals, ...positives };

    return {
      negatives: Object.keys(negatives),
      positives: Object.keys(positives),
      neutrals: Object.keys(neutrals),
      data,
      graphs: response.data.info.graphs,
    };
  } catch (err) {
    throw new Error(err);
  }
};

export const getPlayerDetails = async (playerId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/load-candidate/${playerId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    const player = response.data.info.player;

    // rearrange
    const traits = player.insights["personality traits"];

    // rearrange risk
    const risk = player.insights["risk"];
    let riskComments = {};
    let riskData = Object.keys(risk).reduce((obj, category) => {
      let comments = risk[category]["comments"].reduce((obj, comment) => {
        obj[comment._id] = {
          id: comment._id,
          author: comment.author,
          content: comment.content,
          timestamp: comment.timestamp,
        };

        return obj;
      }, {});

      riskComments = { ...riskComments, ...comments };

      obj[category] = {
        id: category,
        score: risk[category]["score"],
        text: risk[category]["text"],
        comments: Object.keys(comments),
      };

      return obj;
    }, {});

    // rearrange the action plan data
    const actionPlan = player.insights["action_plan"];

    const actionPlanData = Object.keys(actionPlan).reduce((obj, categoryId) => {
      obj[`${playerId}.${categoryId}`] = actionPlan[categoryId];
      return obj;
    }, {});

    const executiveSummery = player.insights["executive_summary"];

    const executiveSummeryData = Object.keys(executiveSummery).reduce(
      (obj, categoryId) => {
        obj[`${playerId}.${categoryId}`] = executiveSummery[categoryId];
        return obj;
      },
      {}
    );

    const issues = player.insights["psychological disorders"];

    // possible duplicate info
    const duplicate = player.possible_duplicate_info;

    const possibleDuplicateInfo = {
      isPossibleDuplicate: duplicate["is_possible_duplicate"],
      possiblePlayerId: duplicate.duplicate_candidate["_id"],
      possiblePlayerName: duplicate.duplicate_candidate["full_name"],
      possiblePlayerBirthday: duplicate.duplicate_candidate["dob"],
      possiblePlayerSchool: duplicate.duplicate_candidate["school"],
      possiblePlayerTeam: duplicate.duplicate_candidate["team"],
      possiblePlayerPhone: duplicate.duplicate_candidate["phone"],
      possiblePlayerEmail: duplicate.duplicate_candidate["email"],
      possiblePlayerCreatedOn: duplicate.duplicate_candidate["created_on"],
      possiblePlayerReviewedOn: duplicate.duplicate_candidate["reviewed_on"],
    };

    const contact = {
      phone: player.phone,
      email: player.email,
      twitter: player.twitter,
      instagram: player.instagram,
      linkedin: player.linkedin,
      snapchat: player.snapchat,
      facebook: player.facebook,
      threads: player.threads
    };

    const developScore = player?.development_score

    const basic = {
      id: player._id,
      birthday: player.dob,
      name: player.full_name,
      firstName: player.first_name,
      lastName: player.last_name,
      homeCountry: player.home_country,
      homeState: player.home_state,
      hometown: player.home_town,
      race: player.race,
      gender: player.gender,
      relationshipStatus: player.relationship_status,
      risk: player.risk,
      school: player.school,
      status: player.status,
      sport: player && player.sport,
      unit: player.unit,
      position: player.position,
      conference: player.conference,
      conferenceType: player.conference_type,
      team: player.team,
      athleteType: player.athlete_type,
      image: player.image_url,
      consent: player.consent,
      isPaid: player.is_paid,
      isPlusReport: player?.isRequestedReport !== undefined ? player.isRequestedReport : false,
      plusReportData: (player?.isRequestedReport !== undefined) ? player?.reqPlusReport : {},
      developmentScore: developScore,
      twitterStatus: player.mim_record.twitter_feed,
      instagramStatus: player.mim_record.instagram_feed,
      sentimentStatus: player.mim_record.sentiment_status,
      psyStatus: player.mim_record.personality_status,
      brain: player.brain,
      analysedOn: player.analysed_on
    };

    const customTraits = player.insights["custom_traits"];
    const behaviourRisk = player.insights["risk"];

    const reports = {
      psychologistReport: player.reports.psychologist_report,
      systemReport: player.reports.system_report,
    };

    const reportSettings = {
      actionPlanReport: player["report_settings"]["action_plan"]["allowed"],
      executiveSummaryReport:
        player["report_settings"]["executive_summary"]["allowed"],
      fullReport: player["report_settings"]["full_report"]["allowed"],
    };

    return {
      id: player._id,
      basic,
      contact,
      traits,
      risk: riskData,
      riskComments,
      actionPlan: actionPlanData,
      executiveSummery: executiveSummeryData,
      customTraits,
      possibleDuplicateInfo,
      issues,
      stats: player.stats,
      notes: player.notes,
      links: player.links,
      message: player.message,
      reports,
      reportSettings,
      behaviourRisk
    };
  } catch (err) {
    throw new Error(err);
  }
};

export const getTeams = async () => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get("/teams", { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    // reorganize data
    let organizationTeams = response.data.info.teams.reduce((obj, item) => {
      obj[item] = item;
      return obj;
    }, {});

    // get deep clone of org teams and delete BCA-DDI from candidate teams
    let candidateTeams = JSON.parse(JSON.stringify(organizationTeams));
    delete candidateTeams["BCA-DDI"];
    let allCandidateTeams = JSON.parse(JSON.stringify(organizationTeams));

    return {
      candidateTeams,
      organizationTeams,
      allCandidateTeams
    };
  } catch (err) {
    throw new Error(err);
  }
};

export const exportPlayerFeed = async (playerId, source) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/export/feed/${playerId}/${source}`, {
      headers,
    });

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

// deprecated
export const exportPlayerReport = async (playerId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/export/report/${playerId}`, {
      responseType: "blob",
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return new Blob([response.data], {type: "application/pdf"});
  } catch (err) {
    throw new Error(err);
  }
};

export const exportSystemReport = async (playerId) => {
  try {
    let headers = getAuthHeader();
    let timeStamp = Date.now();
    let response = await apiHandler.get(
      `/export/report/${playerId}/pre/${timeStamp}`,
      {
        responseType: "blob",
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return new Blob([response.data], {type: "application/pdf"});
  } catch (err) {
    throw new Error(err);
  }
};

export const exportPsychologistReport = async (playerId) => {
  try {
    let headers = getAuthHeader();
    let timeStamp = Date.now();
    let response = await apiHandler.get(
      `/export/report/${playerId}/post/${timeStamp}`,
      {
        responseType: "blob",
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return new Blob([response.data], {type: "application/pdf"});
  } catch (err) {
    throw new Error(err);
  }
};

export const exportFullReport = async (playerId) => {
  try {
    let headers = getAuthHeader();
    let timeStamp = Date.now();
    let response = await apiHandler.get(
      `/export/report/${playerId}/full_report/${timeStamp}`,
      {
        responseType: "blob",
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return new Blob([response.data], {type: "application/pdf"});
  } catch (err) {
    throw new Error(err);
  }
};

export const exportExecutiveSummaryReport = async (playerId) => {
  try {
    let headers = getAuthHeader();
    let timeStamp = Date.now();
    let response = await apiHandler.get(
      `/export/report/${playerId}/executive_summary/${timeStamp}`,
      {
        responseType: "blob",
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return new Blob([response.data], {type: "application/pdf"});
  } catch (err) {
    throw new Error(err);
  }
};

export const exportActionPlanReport = async (playerId) => {
  try {
    let headers = getAuthHeader();
    let timeStamp = Date.now();
    let response = await apiHandler.get(
      `/export/report/${playerId}/action_plan/${timeStamp}`,
      {
        responseType: "blob",
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return new Blob([response.data], {type: "application/pdf"});
  } catch (err) {
    throw new Error(err);
  }
};

export const reGenerateSystemReport = async (playerId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/generate/report/${playerId}/pre`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const reGeneratePsychologistReport = async (playerId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/generate/report/${playerId}/post`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const reGenerateFullReport = async (playerId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(
      `/generate/report/${playerId}/full_report`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const reGenerateActionPlanReport = async (playerId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(
      `/generate/report/${playerId}/action_plan`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const reGenerateExecutiveSummaryReport = async (playerId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(
      `/generate/report/${playerId}/executive_summary`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const postPlayers = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/upload-candidates", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const postPlayer = async (data) => {
  try {
    let headers = getAuthHeader();
    const response = await apiHandler.post("/create-candidate", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postCoach = async (data) => {
  try {
    let headers = getAuthHeader();
    const response = await apiHandler.post("/create-coach", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postRiskComment = async (data) => {
  try {
    let headers = getAuthHeader();
    const response = await apiHandler.post("/insight/add/risk", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.content_id;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteRiskComment = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/insight/delete/risk", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.content_id;
  } catch (err) {
    throw new Error(err);
  }
};

export const postCoachRiskComment = async (data) => {
  try {
    let headers = getAuthHeader();
    const response = await apiHandler.post("/coach/insight/add/risk", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.content_id;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteCoachRiskComment = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/coach/insight/delete/risk", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.content_id;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateRiskScore = async (
  data = {
    candidate_id: "",
    category: "",
    score: 0,
  }
) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/candidate/risk/update/score", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    const payload = response.data.info;

    return {
      candidate_id: payload.candidate_id,
      risk: {
        ...payload.updated_risk,
      },
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const updateCoachRiskScore = async (
  data = {
    coach_id: "",
    category: "",
    score: 0,
  }
) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/coach/risk/update/score", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    const payload = response.data.info;

    return {
      candidate_id: payload.coach_id,
      risk: {
        ...payload.updated_risk,
      },
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const postActionPlanComment = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/insight/add/action-plan", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.content_id;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteActionPlanComment = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/insight/delete/action-plan", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info._id;
  } catch (err) {
    throw new Error(err);
  }
};

export const postExecutiveSummeryComment = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(
      "/insight/add/executive-summary",
      data,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.content_id;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteExecutiveSummeryComment = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(
      "/insight/delete/executive-summary",
      data,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.content_id;
  } catch (err) {
    throw new Error(err);
  }
};

export const postApproveCandidateProfile = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/approve-candidate", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postStartAnalyze = async (playerId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(`/candidate_start_analyze/${playerId}`, {}, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postAnalyzeResults = async (playerId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/candidate_analyzed_results/${playerId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postSentimentResults = async (playerId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/candidate_sentiment_results/${playerId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postRejectCandidateProfile = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post("/reject-candidate", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postReviewCompleted = async (playerId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/review-complete/${playerId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const markAsReviewIncomplete = async (playerId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/review-incomplete/${playerId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postCoachReviewCompleted = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/coach/review-complete/${coachId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const markAsCoachReviewIncomplete = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/coach/review-incomplete/${coachId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const markAsReviewSubmitted = async (playerId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/review-submitted/${playerId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const toggleFullReportPaymentStatus = async (
  playerId,
  allowed = false
) => {
  const status = allowed ? "allow" : "restrict";

  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(
      `/set-report-allowed/${playerId}/full_report/${status}`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const toggleExecutiveSummaryReportPaymentStatus = async (
  playerId,
  allowed = false
) => {
  const status = allowed ? "allow" : "restrict";

  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(
      `/set-report-allowed/${playerId}/executive_summary/${status}`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const toggleActionPlanReportPaymentStatus = async (
  playerId,
  allowed = false
) => {
  const status = allowed ? "allow" : "restrict";

  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(
      `/set-report-allowed/${playerId}/action_plan/${status}`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postUpdateNotes = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post("/insight/update/notes", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const postActionPlanContent = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post("/insight/add/action-plan", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const postExecutiveSummeryContent = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post(
      "/insight/add/executive-summary",
      data,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const markCandidateAsPaid = async (playerId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/is-paid/set/${playerId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const markCandidateAsUnpaid = async (playerId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/is-paid/unset/${playerId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const markCoachAsPaid = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`coach/is-paid/set/${coachId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const markCoachAsUnpaid = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`coach/is-paid/unset/${coachId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const addCandidateLink = async (data) => {
  try {
    const headers = getAuthHeader();

    const response = await apiHandler.post("/insight/add/link", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.links;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteCandidateLink = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.put("/insight/delete/link", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.links;
  } catch (error) {
    throw new Error(error);
  }
};

export const getFilterAthletes = async (model) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post('filter_athlete', model, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.players;
  }
  catch (err) {
    throw new Error(err);
  }
};

export const getFilterPlayers = async (model) => {
  try {
    let headers = getAuthHeader()
    let response = await apiHandler.post('team_players_filter', model, { headers })

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.players;
  }
  catch (err) {
    throw new Error(err);
  }
};

export const postRequestPlanReports = async (candidate_id) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(`/request-plus-report/${candidate_id}`, '', { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info;
  }
  catch (err) {
    throw new Error(err);
  }
};

export const Twitter = async (candidateId,requestUserId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/player_tweets/twitter/${candidateId}/player/${requestUserId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const Instagram = async (candidateId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/player_posts/instagram/${candidateId}/player`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const SentimentScore = async (candidateId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/receptiviti_sentiment_score/${candidateId}/player`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const PsychReviewUpdate = async (candidateId, statusId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/update_player_status/${candidateId}/${statusId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const Big5Info = async (candidateId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/receptiviti_big5_score/${candidateId}/player`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const getVideoTranscript = async (model) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post('/save-player-transcript', model, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  }
  catch (err) {
    throw new Error(err);
  }
};

export const approvePlayer = async (model) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post('/approve-candidate', model, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  }
  catch (err) {
    throw new Error(err);
  }
};

export const plusReport = async (candidateId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(`/request-plus-report/${candidateId}`, '', { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  }
  catch (err) {
    throw new Error(err);
  }
};

export const getLitePlayerDetails = async (playerId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/get-lite-candidate/${playerId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    const player = response.data.info.player;
    const duplicate = player.possible_duplicate_info;

    const possibleDuplicateInfo = {
      isPossibleDuplicate: duplicate["is_possible_duplicate"],
      possiblePlayerId: duplicate.duplicate_candidate["_id"],
      possiblePlayerName: duplicate.duplicate_candidate["full_name"],
      possiblePlayerBirthday: duplicate.duplicate_candidate["dob"],
      possiblePlayerSchool: duplicate.duplicate_candidate["school"],
      possiblePlayerTeam: duplicate.duplicate_candidate["team"],
      possiblePlayerPhone: duplicate.duplicate_candidate["phone"],
      possiblePlayerEmail: duplicate.duplicate_candidate["email"],
      possiblePlayerCreatedOn: duplicate.duplicate_candidate["created_on"],
      possiblePlayerReviewedOn: duplicate.duplicate_candidate["reviewed_on"],
    };

    const basicInfo = {
      id: player._id,
      birthday: player.dob,
      name: player.full_name,
      risk: player.risk,
      school: player.school,
      status: player.status,
      team: player.team,
      isPlusReport: player?.isRequestedReport !== undefined ? player.isRequestedReport : false,
      plusReportData: (player?.isRequestedReport !== undefined) ? player?.reqPlusReport : {},
      twitterStatus: player.mim_record.twitter_feed,
      instagramStatus: player.mim_record.instagram_feed,
      sentimentStatus: player.mim_record.sentiment_status,
      psyStatus: player.mim_record.personality_status,
    };

    const playerContact = {
      phone: player.phone,
      email: player.email,
      twitter: player.twitter,
      twitterSourceln: player.twitter_source_ln,
      instagram: player.instagram,
      instagramSourceln: player.instagram_source_ln,
      linkedin: player.linkedin,
      linkedinSourceln: player.linkedin_source_ln,
      snapchat: player.snapchat,
      facebook: player.facebook,
      team: player.team
    };

    return {
      id: player._id,
      basicInfo,
      possibleDuplicateInfo,
      stats: player.stats,
      playerContact
    };
  } catch (err) {
    throw new Error(err);
  }
};

export const removeFeed = async (playedId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/remove_rt_ad_feed/${playedId}`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  }
  catch (err) {
    throw new Error(err);
  }
};

export const getReceptivitiBig5Score = async (id,type) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/receptiviti_big5_score/${id}/${type}`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateCandidateBasicInfo = async (playerId, data) => {
  try {
        const headers = getAuthHeader();
        const response = await apiHandler.put(`/candidate_basic_info_update/${playerId}`, data, {headers});

        if (response.data.status !== 'Success') {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }
    } catch (err) {
        throw new Error(err);
    }
};

export const getTeamByName = async (name) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/find_team_by_name/${name}`, {headers});

    if (response.data.status !== 'Success') {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.team;
  } catch (err) {
    throw new Error(err);
  }
};

const player = {
  removeFeed,
  getLitePlayerDetails,
  plusReport,
  approvePlayer,
  Twitter,
  Instagram,
  SentimentScore,
  PsychReviewUpdate,
  Big5Info,
  getVideoTranscript,
  getPlayers,
  getPlayerDetails,
  getPlayerFeed,
  getCoachFeed,
  getTeams,
  postPlayers,
  postPlayer,
  postRiskComment,
  deleteRiskComment,
  postUpdateNotes,
  postActionPlanComment,
  deleteActionPlanComment,
  postExecutiveSummeryComment,
  deleteExecutiveSummeryComment,
  exportPlayerFeed,
  exportPlayerReport,
  exportPsychologistReport,
  exportSystemReport,
  postApproveCandidateProfile,
  postRejectCandidateProfile,
  postReviewCompleted,
  markAsReviewIncomplete,
  postActionPlanContent,
  postExecutiveSummeryContent,
  markCandidateAsPaid,
  markCandidateAsUnpaid,
  addCandidateLink,
  deleteCandidateLink,
  reGenerateSystemReport,
  reGeneratePsychologistReport,
  toggleFullReportPaymentStatus,
  toggleExecutiveSummaryReportPaymentStatus,
  toggleActionPlanReportPaymentStatus,
  exportFullReport,
  exportExecutiveSummaryReport,
  exportActionPlanReport,
  reGenerateExecutiveSummaryReport,
  reGenerateActionPlanReport,
  reGenerateFullReport,
  updateRiskScore,
  postCoach,
  getCandidates,
  getFilterAthletes,
  postCoachReviewCompleted,
  markAsCoachReviewIncomplete,
  markCoachAsPaid,
  markCoachAsUnpaid,
  getFilterPlayers,
  updateCoachRiskScore,
  postCoachRiskComment,
  deleteCoachRiskComment,
  postRequestPlanReports,
  markAsReviewSubmitted,
  postStartAnalyze,
  postAnalyzeResults,
  postSentimentResults,
  getReceptivitiBig5Score,
  updateCandidateBasicInfo,
  getTeamByName,
};

export default player;
