import apiHandler, {getAuthHeader} from "./apiHandler";

export const getConference = async () => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get("/conference", { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.conferences;
  } catch (err) {
    throw new Error(err);
  }
};

export const getTeamsByConference = async (conferenceId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(
      `/find_teams_by_conference/${conferenceId}`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info;
  } catch (err) {
    throw new Error(err);
  }
};

export const getSportDetails = async (conferenceId) => {
  try {
    const headers = getAuthHeader();

    const response = await apiHandler.get(
      `/find_sports_by_conference/${conferenceId}`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.sport;
  } catch (err) {
    throw new Error(err);
  }
};

export const addNewConference = async (model) => {
  try {
    let headers = getAuthHeader();
    const response = await apiHandler.post("/create_conference", model, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error("Failed to create conference");
  }
};

export const deleteConference = async (conferenceId) => {
  console.log('api id: ', conferenceId);
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.delete(
      `/conference/delete/${conferenceId}`,
      { headers }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAllCountries = async () => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/country`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    let organizationCountry = response.data.info.country.reduce((obj, item) => {
      obj[item] = item;
      return obj;
    }, {});

    let candidateCountry = JSON.parse(JSON.stringify(organizationCountry));

    return {
      candidateCountry,
    };
  } catch (err) {
    throw new Error(err);
  }
};

export const getStates = async (countryName) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/country_states/${countryName}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    let organizationState = response.data.info.states.reduce((obj, item) => {
      obj[item] = item;
      return obj;
    }, {});

    let candidateState = JSON.parse(JSON.stringify(organizationState));

    // return response.data;
    return {
      candidateState,
    };
  } catch (err) {
    throw new Error(err);
  }
};

export const getConferenceByType = async (conference_type) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(
      `/get_conferences_by_type/${conference_type}`,
      { headers }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.conferences;
  } catch (err) {
    throw new Error(err);
  }
};

export const createNewTeam = async (model) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(`/create-team`, model, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info;
  } catch (err) {
    throw new Error(err);
  }
};

export const getConferenceById = async (conferenceId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/conference/${conferenceId}`, { headers });

    if (response.data.status !== 'Success') {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.conference;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateConference = async (conferenceId, data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.put(`/conference/update/${conferenceId}`, data, { headers });

    if (response.data.status !== 'Success') {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.conferences;
  } catch (err) {
    throw new Error(err);
  }
};

const conference = {
  getConference,
  getTeamsByConference,
  getConferenceById,
  addNewConference,
  updateConference,
  deleteConference,
  getAllCountries,
  getStates,
  getConferenceByType,
  getSportDetails,
  createNewTeam,
};

export default conference;
