import MentalMetrixWhite from "../../../../assets/TeamQ_white.svg";

const MentalMetrixWhiteLogo = ({ width }) => {
  return (
    <img
      src={MentalMetrixWhite}
      alt="mentalMetrixWhite"
      style={{ width: width ?? '100%', paddingLeft: 15   }}
    />
  );
};

export default MentalMetrixWhiteLogo;
