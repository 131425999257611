import { Editor } from 'react-draft-wysiwyg';
import {makeStyles} from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
    editor: {
        fontSize: 16,
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        fontWeight: 400,
        minHeight: 'auto',
        border: `1px solid ${theme.colors.veryLightGrey}`,
        padding: '5px 10px',
    },
}));

const FormEditor = ({ editorState, onEditorStateChange }) => {
    const classes = useStyles();
    return (
        <Editor
            wrapperClassName={classes.editor}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbar={{
                options: [
                    "blockType",
                    "inline",
                    "list",
                    "history",
                ],
                inline: {
                    options: ["bold", "italic", "strikethrough", "monospace"],
                },
                blockType: {
                    inDropdown: true,
                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                },
                list: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['unordered', 'ordered', 'indent', 'outdent'],
                },
            }}
        />
    );
};

export default FormEditor;