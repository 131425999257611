import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import {LinearBar} from "modules/shared/Charts";

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: 'column',
    padding: '16px 0px',
  },
  container: {
    display: "flex",
    justifyContent: 'center',
  },
  details: {
    display: "flex",
    flex: 1,
    paddingRight: theme.spacing(1),
    color: theme.palette.text.primary,
    fontSize: 16,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
}));

const ChartAndDetails = (props) => {
  let classes = useStyles();
  let {
    diagnosis,
    score = 0,
    gradientColors  = []
  } = props;

  return (
    <div className={classes.root}>
      <div style={{ width: "100%" }}>
        <LinearBar score={score} gradientColors={gradientColors}/>
      </div>
      <div className={classes.details}>
        <div
          style={{ display: "flex", flex: 1, justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle2" style={{ paddingLeft: 16 }}>
              {diagnosis}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    team: state.account.team,
    accountType: state.account.accountType,
  };
};

export default connect(mapStateToProps)(ChartAndDetails);