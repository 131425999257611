import {connect} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {ActionContext} from "../../../contexts";
import {Container, FormEditor, RunDashedLogo, Title} from "../../shared";
import {Box, FormControl, FormLabel, Grid} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Field, Form, Formik} from "formik";
import {Link, useHistory, useParams} from "react-router-dom";
import {number, string} from "yup";
import {GlobalCss} from "../../../config/GlobalCss";
import {TextField} from "formik-material-ui";
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import clsx from "clsx";
import Button from "../../shared/Button";
import useStyles from './styles';

const SubScoreForm = (props) => {
    const classes = useStyles();
    const {assessmentCommentActions} = useContext(ActionContext);
    const {commentId} = useParams();
    const history = useHistory();
    const {formState, loading, subAttribute, subAttributeId} = props;
    const formType = formState === 'create';
    let [watchOutEditorState, setWatchOutEditorState] = useState(EditorState.createEmpty());
    let [tip1EditorState, setTip1EditorState] = useState(EditorState.createEmpty());
    let [tip2EditorState, setTip2EditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (subAttribute) {
            try {
                const watchOutMarkToDraft = markdownToDraft(subAttribute.watch_out_statement);
                const watchOutContentState = convertFromRaw(watchOutMarkToDraft);
                setWatchOutEditorState(EditorState.createWithContent(watchOutContentState));

                const tip1MarkToDraft = markdownToDraft(subAttribute.tip_1);
                const tip1ContentState = convertFromRaw(tip1MarkToDraft);
                setTip1EditorState(EditorState.createWithContent(tip1ContentState));

                const tip2MarkToDraft = markdownToDraft(subAttribute.tip_2);
                const tip2ContentState = convertFromRaw(tip2MarkToDraft);
                setTip2EditorState(EditorState.createWithContent(tip2ContentState));
            } catch (error) {
                throw new Error(error);
            }
        }
    }, [subAttribute]);

    const convertToUnderscore = (str) => {
        let lowercase = str.toLowerCase()
        return lowercase?.split(' ').join('_');
    };

    const handleSubmit = (values) => {
        const watchOutContentState = watchOutEditorState.getCurrentContent();
        const watchOutRawContent = convertToRaw(watchOutContentState);
        const watchOutMarkdown = draftToMarkdown(watchOutRawContent);

        const tip1ContentState = tip1EditorState.getCurrentContent();
        const tip1RawContent = convertToRaw(tip1ContentState);
        const tip1Markdown = draftToMarkdown(tip1RawContent);

        const tip2ContentState = tip2EditorState.getCurrentContent();
        const tip2RawContent = convertToRaw(tip2ContentState);
        const tip2Markdown = draftToMarkdown(tip2RawContent);

        let data = {
            commentId: commentId,
            subAttribute: convertToUnderscore(values.subAttribute),
            scoreStart: values.scoreStart,
            scoreEnd: values.scoreEnd,
            strengthStatement: values.strengthStatement,
            watchOutStatement: watchOutMarkdown,
            tipHeadline: values.tipHeadline,
            tip1: tip1Markdown,
            tip2: tip2Markdown,
        }

        formType ? assessmentCommentActions.createSubAttribute(data, commentId) : assessmentCommentActions.updateSubAttribute(subAttributeId, data, commentId);

        history.push(`/assessment-comment/update/${commentId}`)
    };

    const splitText = (str) => {
        let splitStr = str?.split('_')
        if (splitStr?.length > 1) {
            return splitStr.join(' ');
        }
        else {
            return str;
        }
    };

    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={8} style={{height: '100%', minHeight: '600px', maxHeight: 'auto'}}>
                    <div style={{margin: '0rem 3rem'}}>
                        {
                            loading && loading ? (
                                <Box>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Formik
                                    initialValues={{
                                        subAttribute: splitText(subAttribute.sub_attribute) || '',
                                        scoreStart: subAttribute.score_start || 0,
                                        scoreEnd: subAttribute.score_end || 0,
                                        strengthStatement: subAttribute.strength_statement || '',
                                        watchOutStatement: watchOutEditorState || '',
                                        tipHeadline: subAttribute.tip_headline || '',
                                        tip1: tip1EditorState || '',
                                        tip2: tip2EditorState || '',
                                    }}
                                    validator={{
                                        subAttribute: string()
                                            .trim()
                                            .matches(
                                                /^[a-zA-Z0-9]+(([a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/,
                                                "Enter a valid sub attribute, no special characters allowed."
                                            )
                                            .required('A sub attribute is required'),
                                        scoreStart: number().nullable().required('Select a starting score'),
                                        scoreEnd: number().nullable().required('Select a end score'),
                                        strengthStatement: string()
                                            .trim()
                                            .matches(
                                                /^[a-zA-Z0-9]+(([a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/,
                                                "Enter a valid strength statement, no special characters allowed."
                                            )
                                            .required('A strength statement is required'),
                                        tipHeadline: string()
                                            .trim()
                                            .matches(
                                                /^[a-zA-Z0-9]+(([a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/,
                                                "Enter a valid tip headline, no special characters allowed."
                                            )
                                            .required('A tip headline is required'),
                                    }}
                                    onSubmit={(values) => handleSubmit(values)}
                                >
                                    <Form autoComplete='off'>
                                        <GlobalCss/>
                                        <div className={classes.form}>
                                            {formType ? <Title title='Create Sub Attribute'/> :
                                                <Title title='Update' text={splitText(subAttribute.sub_attribute)}/>}
                                        </div>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Field
                                                    name="subAttribute"
                                                    label="Sub Attribute"
                                                    placeholder="Sub Attribute"
                                                    component={TextField}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    autoFocus
                                                    style={{marginTop: '1rem'}}
                                                />
                                            </Grid>
                                            <Grid item xs={12}
                                                  style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <Field
                                                    name="scoreStart"
                                                    label="Starting score"
                                                    placeholder="Starting score"
                                                    component={TextField}
                                                    type='number'
                                                    variant="outlined"
                                                    required
                                                    autoFocus
                                                    style={{width: '49%'}}
                                                />
                                                <Field
                                                    name="scoreEnd"
                                                    label="End score"
                                                    placeholder={formType ? "End score" : null}
                                                    component={TextField}
                                                    type='number'
                                                    variant="outlined"
                                                    required
                                                    autoFocus
                                                    style={{width: '49%'}}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field
                                                    name="strengthStatement"
                                                    label="Strength Statement"
                                                    placeholder="Strength Statement"
                                                    component={TextField}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    autoFocus
                                                    style={{marginTop: '1rem'}}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <FormLabel style={{marginBottom: '5px'}}>Watch Out
                                                        Statement</FormLabel>
                                                    <FormEditor editorState={watchOutEditorState}
                                                                onEditorStateChange={setWatchOutEditorState}/>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field
                                                    name="tipHeadline"
                                                    label="Tip Headline"
                                                    placeholder="Tip Headline"
                                                    component={TextField}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    autoFocus
                                                    style={{marginTop: '1rem'}}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <FormLabel style={{marginBottom: '5px'}}>Tip 1</FormLabel>
                                                    <FormEditor editorState={tip1EditorState}
                                                                onEditorStateChange={setTip1EditorState}/>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <FormLabel style={{marginBottom: '5px'}}>Tip 2</FormLabel>
                                                    <FormEditor editorState={tip2EditorState}
                                                                onEditorStateChange={setTip2EditorState}/>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <div className={clsx(classes.content)}>
                                            <Link
                                                to={`/assessment-comment/update/${commentId}`}
                                                className={classes.btnSecondary}
                                            >
                                                Cancel
                                            </Link>
                                            <Button
                                                className={classes.btnPrimary}
                                                type="submit"
                                            >
                                                {formType ? 'Create' : 'Update'}
                                            </Button>
                                        </div>
                                    </Form>
                                </Formik>
                            )
                        }
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <RunDashedLogo/>
                </Grid>
            </Grid>
        </Container>
    )
};

let mapStateToProps = (state) => {
    return {
        subAttributeId: state.assessmentComments.subAttributeId,
        loading: state.ui.assessmentComments.loadingSubAttribute,
        formState: state.ui.sports.selectedFormState,
        subAttribute: state.assessmentComments.selectedSubAttribute,
    };
};

export default connect(mapStateToProps)(SubScoreForm);