import * as types from "./appTypes";

const openDrawer = () => {
  return {
    type: types.OPEN_DRAWER,
  };
};

const closeDrawer = () => {
  return {
    type: types.CLOSE_DRAWER,
  };
};

const toggleDrawer = () => {
  return {
    type: types.TOGGLE_DRAWER,
  };
};

const appBarTitle = (title) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.TITLE_SUCCESS,
        payload: title,
      });
    } catch (err) {
      dispatch({
        type: types.TITLE_FAILED,
      })
    }
  };
}

const appAction = {
  openDrawer,
  closeDrawer,
  toggleDrawer,
  appBarTitle,
};

export default appAction;
