import { combineReducers } from "redux";
import { statusTypes, consentTypes } from "../../../store/constants";
import * as types from "./playerTypes";

const selectedPlayerId = (state = null, action) => {
  switch (action.type) {
    case types.SELECT_PLAYER: {
      return action.payload.playerId;
    }

    case types.DESELECT_PLAYER: {
      return null;
    }

    default:
      return state;
  }
};

const alreadyRequestedPlusReport = (state = false, action) => {
  switch (action.type) {
    case types.SAVE_ALLREADY_REQUESTED_PLUS_REPORTS: {
      return action.payload.data
    }

    default:
      return state;
  }
}

const searchKey = (state = "", action) => {
  switch (action.type) {
    case types.SEARCH_PLAYER: {
      return action.payload.key;
    }

    case types.RESET_SEARCH: {
      return "";
    }

    default:
      return state;
  }
};

const statusFilter = (state = statusTypes.default, action) => {
  switch (action.type) {
    case types.FILTER_BY_STATUS: {
      return action.payload.filter;
    }

    default:
      return state;
  }
};

const conferenceFilter = (state = "conference", action) => {
  switch (action.type) {
    case types.FILTER_BY_CONFERENCE: {
      return action.payload.filter;
    }
    default:
      return state;

  }
}

const sportFilter = (state = "sport", action) => {
  switch (action.type) {
    case types.FILTER_BY_SPORT: {
      return action.payload.filter;
    }
    default:
      return state;

  }
}

const riskFilter = (state = "risk", action) => {
  switch (action.type) {
    case types.FILTER_BY_RISK: {
      return action.payload.filter;
    }

    default:
      return state;
  }
};

const consentFilter = (state = consentTypes.default, action) => {
  switch (action.type) {
    case types.FILTER_BY_CONSENT: {
      return action.payload.filter;
    }

    default:
      return state;
  }
};

const teamFilter = (state = "team", action) => {
  switch (action.type) {
    case types.FILTER_BY_TEAM: {
      return action.payload.filter;
    }

    default:
      return state;
  }
};

const loadingTeams = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_GET_TEAMS: {
      return true;
    }

    case types.GET_CANDIDATE_TEAMS_SUCCESS:
    case types.GET_ORGANIZATION_TEAMS_SUCCESS:
    case types.GET_TEAMS_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const updatingNotes = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_UPDATE_NOTES: {
      return true;
    }

    case types.UPDATE_NOTES_SUCCESS:
    case types.UPDATE_NOTES_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const candidateTeams = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_CANDIDATE_TEAMS_SUCCESS: {
      return { ...payload.teams };
    }

    default:
      return state;
  }
};

const allCandidateTeams = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_ALL_CANDIDATE_TEAMS_SUCCESS: {
      return { ...payload.teams };
    }

    default:
      return state;
  }
};

const organizationTeams = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_ORGANIZATION_TEAMS_SUCCESS: {
      return { ...payload.teams };
    }

    default:
      return state;
  }
};

const loadingPlayerList = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_GET_PLAYERS: {
      return true;
    }

    case types.GET_PLAYERS_SUCCESS:
    case types.GET_PLAYERS_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const loadAllCandidates = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_GET_CANDIDATES: {
      return true
    }

    case types.GET_CANDIDATES_SUCCESS:
    case types.GET_CANDIDATES_FAILED: {
      return false
    }

    default:
      return state;
  }
}

const loadingPlayer = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_GET_PLAYER: {
      const { playerId } = payload;
      return {
        ...state,
        [playerId]: true,
      };
    }

    case types.GET_PLAYER_SUCCESS:
    case types.GET_PLAYER_FAILED: {
      const { playerId } = payload;
      return {
        ...state,
        [playerId]: false,
      };
    }

    default:
      return state;
  }
};

const loadingLitePlayer = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_GET_LITE_PLAYER: {
      const { playerId } = payload;
      return {
        ...state,
        [playerId]: true,
      };
    }

    case types.GET_LITE_PLAYER_SUCCESS:
    case types.GET_LITE_PLAYER_FAILED: {
      const { playerId } = payload;
      return {
        ...state,
        [playerId]: false,
      };
    }

    default:
      return state;
  }
};

const loadingFilters = (state = false, action) => {
  switch (action.type) {
    case types.GET_FILTER_CANDIDATES_REQUEST: {
      return true
    }

    case types.GET_FILTER_CANDIDATES_SUCCESS:
    case types.GET_FILTER_CANDIDATES_FAILED: {
      return false
    }

    default:
      return state
  }

}

const loadingPlayerFilters = (state = false, action) => {
  switch (action.type) {
    case types.GET_FILTER_PLAYERS_REQUEST: {
      return true
    }

    case types.GET_FILTER_PLAYERS_SUCCESS:
    case types.GET_FILTER_PLAYERS_FAILED: {
      return false
    }

    default:
      return state
  }

}

const uploadingPlayers = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_UPLOAD_PLAYERS: {
      return true;
    }

    case types.UPLOAD_PLAYERS_SUCCESS:
    case types.UPLOAD_PLAYERS_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const submittingPlayer = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_CREATE_PLAYER: {
      return true;
    }

    case types.CREATE_PLAYER_SUCCESS:
    case types.CREATE_PLAYER_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const submittingCoach = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_CREATE_COACH: {
      return true;
    }

    case types.CREATE_COACH_SUCCESS:
    case types.CREATE_COACH_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const loadingTwitterFeed = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.REQUEST_GET_PLAYER_FEED &&
    payload.source === "twitter"
  ) {
    return {
      ...state,
      [payload.playerId]: true,
    };
  }

  if (
    (action.type === types.GET_PLAYER_FEED_SUCCESS ||
      action.type === types.GET_PLAYER_FEED_FAILED) &&
    payload.source === "twitter"
  ) {
    return {
      ...state,
      [payload.id]: false,
    };
  }

  return state;
};

const loadingInstaFeed = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.REQUEST_GET_PLAYER_FEED &&
    payload.source === "instagram"
  ) {
    return {
      ...state,
      [payload.playerId]: true,
    };
  }

  if (
    (action.type === types.GET_PLAYER_FEED_SUCCESS ||
      action.type === types.GET_PLAYER_FEED_FAILED) &&
    payload.source === "instagram"
  ) {
    return {
      ...state,
      [payload.id]: false,
    };
  }

  return state;
};

const loadingYoutubeFeed = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.REQUEST_GET_PLAYER_FEED &&
    payload.source === "youtube"
  ) {
    return {
      ...state,
      [payload.playerId]: true,
    };
  }

  if (
    (action.type === types.GET_PLAYER_FEED_SUCCESS ||
      action.type === types.GET_PLAYER_FEED_FAILED) &&
    payload.source === "youtube"
  ) {
    return {
      ...state,
      [payload.id]: false,
    };
  }

  return state;
};

const loadingNewspaperFeed = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.REQUEST_GET_PLAYER_FEED &&
    payload.source === "newspaper"
  ) {
    return {
      ...state,
      [payload.playerId]: true,
    };
  }

  if (
    (action.type === types.GET_PLAYER_FEED_SUCCESS ||
      action.type === types.GET_PLAYER_FEED_FAILED) &&
    payload.source === "newspaper"
  ) {
    return {
      ...state,
      [payload.id]: false,
    };
  }

  return state;
};

const loadingPlayerFeed = combineReducers({
  twitter: loadingTwitterFeed,
  instagram: loadingInstaFeed,
  youtube: loadingYoutubeFeed,
  newspaper: loadingNewspaperFeed,
});

const approvingCandidateProfile = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_APPROVE_CANDIDATE_PROFILE: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.APPROVE_CANDIDATE_PROFILE_SUCCESS:
    case types.APPROVE_CANDIDATE_PROFILE_FAILED: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const rejectingCandidateProfile = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_REJECT_CANDIDATE_PROFILE: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.REJECT_CANDIDATE_PROFILE_FAILED:
    case types.REJECT_CANDIDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const updatingRiskComments = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_ADD_RISK_COMMENT: {
      const { playerId, categoryId } = payload;
      return {
        ...state,
        [`${playerId}.${categoryId}`]: true,
      };
    }

    case types.ADD_RISK_COMMENT_SUCCESS: {
      const { playerId, categoryId } = payload;
      return {
        ...state,
        [`${playerId}.${categoryId}`]: false,
      };
    }
    case types.ADD_RISK_COMMENT_FAILED: {
      const { playerId, categoryId } = payload;
      return {
        ...state,
        [`${playerId}.${categoryId}`]: false,
      };
    }

    default:
      return state;
  }
};

const loadingMarkAsReviewCompleted = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_REVIEW_COMPLETE_CANDIDATE: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.REVIEW_COMPLETE_CANDIDATE_FAILED:
    case types.REVIEW_COMPLETE_CANDIDATE_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const loadingMarkAsReviewIncompleted = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUSET_CANDIDATE_REVIEW_INCOMPLETE: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.CANDIDATE_REVIEW_INCOMPLETE_SUCCESS:
    case types.CANDIDATE_REVIEW_INCOMPLETE_FAILED: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const exportingFeed = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_EXPORT_FEED: {
      return {
        ...state,
        [`${payload.playerId}.${payload.source}`]: true,
      };
    }

    case types.EXPORT_FEED_SUCCESS:
    case types.EXPORT_FEED_FAILED: {
      return {
        ...state,
        [`${payload.playerId}.${payload.source}`]: false,
      };
    }

    default:
      return state;
  }
};

const exportingPlayerReport = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_EXPORT_PLAYER_REPORT: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.EXPORT_PLAYER_REPORT_SUCCESS:
    case types.EXPORT_PLAYER_REPORT_FAILED: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const exportingSystemReport = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_EXPORT_SYSTEM_REPORT: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.EXPORT_SYSTEM_REPORT_SUCCESS:
    case types.EXPORT_SYSTEM_REPORT_FAIL: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const exportingFullReport = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_EXPORT_FULL_REPORT: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.EXPORT_FULL_REPORT_SUCCESS:
    case types.EXPORT_FULL_REPORT_FAIL: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const exportingActionPlanReport = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_EXPORT_ACTION_PLAN_REPORT: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.EXPORT_ACTION_PLAN_REPORT_SUCCESS:
    case types.EXPORT_ACTION_PLAN_REPORT_FAIL: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const exportingExecutiveSummaryReport = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_EXPORT_EXECUTIVE_SUMMARY_REPORT: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.EXPORT_EXECUTIVE_SUMMARY_REPORT_SUCCESS:
    case types.EXPORT_EXECUTIVE_SUMMARY_REPORT_FAIL: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const exportingPsycologistReport = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_EXPORT_PSYCOLOGIST_REPORT: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.EXPORT_PSYCOLOGIST_REPORT_SUCCESS:
    case types.EXPORT_PSYCOLOGIST_REPORT_FAIL: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const addingActionPlanContent = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_ADD_ACTION_PLAN_COMMENT: {
      const { playerId, categoryId } = payload;
      return {
        ...state,
        [`${playerId}.${categoryId}`]: true,
      };
    }

    case types.ADD_ACTION_PLAN_COMMENT_SUCCESS:
    case types.ADD_ACTION_PLAN_CONTENT_FAILED: {
      const { playerId, categoryId } = payload;
      return {
        [`${playerId}.${categoryId}`]: false,
      };
    }
    default:
      return state;
  }
};

const addingExecutiveSummeryContent = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_ADD_EXECUTIVE_SUMMERY_CONTENT: {
      const { playerId, categoryId } = payload;
      return {
        ...state,
        [`${playerId}.${categoryId}`]: true,
      };
    }

    case types.ADD_EXECUTIVE_SUMMERY_COMMENT_SUCCESS:
    case types.ADD_EXECUTIVE_SUMMERY_CONTENT_FAILED: {
      const { playerId, categoryId } = payload;
      return {
        ...state,
        [`${playerId}.${categoryId}`]: false,
      };
    }

    default:
      return state;
  }
};

const updatingCandidatePaidStatus = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_TOGGLE_CANDIDATE_PAID_STATUS: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.TOGGLE_CANDIDATE_PAID_STATUS_SUCCESS:
    case types.TOGGLE_CANDIDATE_PAID_STATUS_FAILED: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const addingCandidateLink = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_ADD_CANDIDATE_LINK: {
      return {
        [payload.playerId]: true,
      };
    }

    case types.ADD_CANDIDATE_LINK_SUCCESS:
    case types.ADD_CANDIDATE_LINK_FAIL: {
      return {
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const deletingCandidateLink = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_DELETE_CANDIDATE_LINK: {
      return {
        [payload.playerId]: true,
      };
    }

    case types.DELETE_CANDIDATE_LINK_FAIL:
    case types.DELETE_CANDIDATE_LINK_SUCCESS: {
      return {
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const regeneratingSystemReport = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_REGENERATE_SYSTEM_REPORT: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.REGENERATE_SYSTEM_REPORT_SUCCESS:
    case types.REGENERATE_SYSTEM_REPORT_FAIL: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }
    default:
      return state;
  }
};

const regeneratingPsychReport = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_REGENERATE_PSYCH_REPORT: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.REGENERATE_PSYCH_REPORT_SUCCESS:
    case types.REGENERATE_PSYCH_REPORT_FAIL: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }
    default:
      return state;
  }
};

const regeneratingFullReport = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_REGENERATE_FULL_REPORT: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.REGENERATE_FULL_REPORT_SUCCESS:
    case types.REGENERATE_FULL_REPORT_FAIL: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }
    default:
      return state;
  }
};

const regeneratingActionPlanReport = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_REGENERATE_ACTION_PLAN_REPORT: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.REGENERATE_ACTION_PLAN_REPORT_SUCCESS:
    case types.REGENERATE_ACTION_PLAN_REPORT_FAIL: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }
    default:
      return state;
  }
};

const regeneratingExecutiveSummaryReport = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_REGENERATE_EXECUTIVE_SUMMARY_REPORT: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.REGENERATE_EXECUTIVE_SUMMARY_REPORT_SUCCESS:
    case types.REGENERATE_EXECUTIVE_SUMMARY_REPORT_FAIL: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }
    default:
      return state;
  }
};

const loadingFullReportPaymentStatus = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_TOGGLE_FULL_REPORT_PAYMENT_STATUS: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.TOGGLE_FULL_REPORT_PAYMENT_STATUS_SUCCESS:
    case types.TOGGLE_FULL_REPORT_PAYMENT_STATUS_FAILED: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const loadingExecutiveSummaryReportPaymentStatus = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_FAILED:
    case types.TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const loadingActionPlanReportPaymentStatus = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS: {
      return {
        ...state,
        [payload.playerId]: true,
      };
    }

    case types.TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS_SUCCESS:
    case types.TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS_FAILED: {
      return {
        ...state,
        [payload.playerId]: false,
      };
    }

    default:
      return state;
  }
};

const updatingRiskScore = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_UPDATE_RISK_SCORE: {
      const { playerId, categoryId } = payload;
      return {
        ...state,
        [`${playerId}.${categoryId}`]: true,
      };
    }

    case types.UPDATE_RISK_SCORE_SUCCESS:
    case types.UPDATE_RISK_SCORE_FAIL: {
      const { playerId, categoryId } = payload;
      return {
        ...state,
        [`${playerId}.${categoryId}`]: false,
      };
    }

    default:
      return state;
  }
};

const loadingReqPlanReports = (state = false, action) => {
  switch (action.type) {
    case types.GET_REQUEST_PLAN_REPORTS: {
      return true
    }

    case types.GET_REQUEST_PLAN_REPORTS_SUCCESS:
    case types.GET_REQUEST_PLAN_REPORTS_FAILED: {
      return false
    }

    default:
      return state
  }

}

const isAthleteFormSubmit = (state = false, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.INDIVIDUAL_ATHLETE_FORM_SUBMITTED: {
      return payload
    }

    case types.INDIVIDUAL_ATHLETE_FORM_NOT_SUBMITTED: {
      return payload
    }

    default:
      return state
  }
}

const loadingSentimentAnalysisStatus = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_CANDIDATE_SENTIMENT_RESULTS: {
      return {
        ...state,
        [payload.playerId]: payload.value,
      };
    }

    case types.CANDIDATE_SENTIMENT_RESULTS_SUCCESS: {
      return {
        ...state,
        [payload.playerId]: payload.value,
      };
    }

    case types.CANDIDATE_SENTIMENT_RESULTS_FAILED: {
      return {
        ...state,
        [payload.playerId]: payload.value,
      };
    }

    default:
      return state;
  }
};
const submittingBrainReceptivity = (state = false, action) => {
  switch (action.type) {
      case types.GET_RECEPTIVITI_SCORE: {
          return true;
      }

      case types.GET_RECEPTIVITI_SCORE_SUCCESS: {
          return false;
      }
      case types.GET_RECEPTIVITI_SCORE_FAILED: {
          return false;
      }

      default:
          return state;
  }
};

const loadingSelectedTeamByName = (state = false, action) => {
  switch (action.type) {
    case types.GET_TEAM_BY_NAME_REQUEST: {
      return true;
    }
    case types.GET_TEAM_BY_NAME_SUCCESS:
    case types.GET_TEAM_BY_NAME_FAILED: {
      return false;
    }

    default:
      return false;
  }
};

const loadingCommunicationData = (state = false, action) => {
  switch (action.type) {
    case types.GET_COMMUNICATION_DATA_REQUEST: {
      return true;
    }

    case types.GET_COMMUNICATION_DATA_SUCCESS:
    case types.GET_COMMUNICATION_DATA_FAILED: {
     return false;
    }

    default: return state;
  }
};

export default combineReducers({
  loadingLitePlayer,
  selectedPlayerId,
  searchKey,
  statusFilter,
  riskFilter,
  consentFilter,
  teamFilter,
  conferenceFilter,
  sportFilter,
  candidateTeams,
  organizationTeams,
  allCandidateTeams,
  loadingTeams,
  loadingPlayerList,
  loadingPlayer,
  loadingPlayerFeed,
  uploadingPlayers,
  submittingPlayer,
  submittingCoach,
  approvingCandidateProfile,
  rejectingCandidateProfile,
  updatingNotes,
  updatingRiskComments,
  loadingMarkAsReviewCompleted,
  loadingMarkAsReviewIncompleted,
  exportingPlayerReport,
  exportingPsycologistReport,
  exportingSystemReport,
  exportingFeed,
  addingActionPlanContent,
  addingExecutiveSummeryContent,
  updatingCandidatePaidStatus,
  addingCandidateLink,
  deletingCandidateLink,
  regeneratingSystemReport,
  regeneratingPsychReport,
  loadingFullReportPaymentStatus,
  loadingExecutiveSummaryReportPaymentStatus,
  loadingActionPlanReportPaymentStatus,
  exportingFullReport,
  exportingActionPlanReport,
  exportingExecutiveSummaryReport,
  regeneratingFullReport,
  regeneratingActionPlanReport,
  regeneratingExecutiveSummaryReport,
  updatingRiskScore,
  loadAllCandidates,
  loadingFilters,
  loadingPlayerFilters,
  loadingReqPlanReports,
  isAthleteFormSubmit,
  alreadyRequestedPlusReport,
  loadingSentimentAnalysisStatus,
  submittingBrainReceptivity,
  loadingSelectedTeamByName,
  loadingCommunicationData,
});
