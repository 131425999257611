import React, { useContext, useEffect , useState } from "react";
import { connect} from "react-redux";
import { ActionContext } from "../../../contexts";
import Filter from "./Filter";
import List from "./List"
import {Container} from "../../shared";

function Main(props) {
    let { updateFilter } = props;

    let actions = useContext(ActionContext);
    let { playerActions, sportsActions } = actions;

    const [selectedPage, setSelectedPage] = useState('list')
    const [filterValue, setFilterValue] = useState(false)

    useEffect(() => {
        let model = {
            conference: updateFilter.conference ? [updateFilter.conference] : [],
            sport: updateFilter.sport ? [updateFilter.sport] : [],
            unit: updateFilter.unit ? [updateFilter.unit] : [],
            position: updateFilter.position ? [updateFilter.position] : [],
            athleteType: updateFilter.athleteType === "All" ? [] : [updateFilter.athleteType],
            conferenceType: updateFilter.conferenceType === "All" ? [] : [updateFilter.conferenceType],
            team : updateFilter.team ? [updateFilter.team] : []
        }

        playerActions.getAllFiltersCandidates(model)
    }, []);

    useEffect(() => {
        return () => {
            playerActions.isFilteredData(false)
            setFilterValue(false)
            playerActions.resetFilterValue()
            sportsActions.resetUnitsApi()
            sportsActions.resetSportsApi()
        }
    },[])

    const handlePageChange = (value) => {
        setSelectedPage(value)
    }

    const handleFilterApply = (value) => {
        setFilterValue(value)
    }

    return (
        <Container>
            {
                selectedPage === 'list' ?
                    <List pageChange={handlePageChange} filterValue={filterValue} isFilteredApply={handleFilterApply}/>
                    :
                    <Filter pageChange={handlePageChange} isFilteredApply={handleFilterApply}/>
            }
        </Container>
    )
}

let mapStateToProps = (state) => {

    return {
        updateFilter: state.player.updateFilter
    };
};
export default connect(mapStateToProps)(Main);