import MuiAppBar from "@material-ui/core/AppBar";
import Filters from "../../players/components/Filters";
import AccountPopover from "../AccountPopover";
import NotificationsPopover from "../NotificationsPopover";
import { Grid, Hidden, makeStyles, Toolbar } from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import Drawer from "../components/Drawer";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { accountTypes } from '../../../store/constants';
import {connect} from "react-redux";
import {Title} from "../../shared";
import {useEffect} from "react";

let useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.colors.white,
  },
  logo: {
    display: "flex",
    alignItems: "center",
    width: 257,
    marginLeft: -8,
    paddingLeft: '2rem',
  },
  image: {
    cursor: "pointer",
  },
  filters: {
    flexGrow: 1,
  },
  equalizerIcon: {
    color: theme.colors.slate,
    cursor: 'pointer'
  }
}));

let AppBar = (props) => {
  let classes = useStyles();
  let history = useHistory();
  let isPlayersRoute = useRouteMatch({ path: "/players", strict: true });
  const { pageName, isDrawerOpen } = props;

  useEffect(() => {
    if (pageName === '') {
      history.push('/')
    }
  }, [pageName]);

  let handleRedirectToUpdatePage = () => {
    history.push("/todays-update");
  };

  return (
    <div>
      <MuiAppBar position="fixed" className={classes.appBar} {...props}>
        <Drawer />
        <Toolbar variant="dense">
          <div style={{marginLeft: !isDrawerOpen ? '4rem' :'18rem'}}>
            <Title title={pageName ? pageName : 'Dashboard'} />
          </div>
          <div className={classes.filters}>
            <Hidden smDown>{isPlayersRoute ? <Filters /> : null}</Hidden>
          </div>
          <div>
            <Grid container spacing={2} direction="row-reverse">
              <Grid item>
                <AccountPopover />
              </Grid>
              <Grid item style={{ marginTop: '0.4rem' }}>
                <NotificationsPopover />
              </Grid>
              {
                (props.accountType === accountTypes.superAdmin || props.accountType === accountTypes.mmDdiAdmin) ?
                  <Grid item style={{ marginTop: '0.4rem' }}>
                    <span onClick={handleRedirectToUpdatePage}>
                      <EqualizerIcon className={classes.equalizerIcon} />
                    </span>
                  </Grid>
                  :
                  ""
              }
            </Grid>
          </div>
        </Toolbar>
        <Hidden mdUp>
          {isPlayersRoute ? (
            <Toolbar variant="dense" style={{ padding: 0 }}>
              <Filters />
            </Toolbar>
          ) : null}
        </Hidden>
      </MuiAppBar>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    isDrawerOpen: state.ui.app.isDrawerOpen,
    pageName: state.ui.app.selectedPageTitle,
  };
};

export default connect(mapStateToProps)(AppBar);
