import {connect} from "react-redux";
import React, {useContext, useEffect} from "react";
import {ActionContext} from "../../../contexts";
import {Title} from "../../shared";
import {Box, Grid, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import {Link, useLocation} from "react-router-dom";
import CircularProgress from "../../shared/CircularProgress";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import useStatusColor from "../../../utils/useStatusSelector";
import useStyles from './styles';

const CommentAttributes = (props) => {
    const {assessmentCommentActions} = useContext(ActionContext);
    const classes = useStyles();
    const { getColorName } = useStatusColor();
    const location = useLocation();
    const {commentAttributes, commentId, loading} = props;

    useEffect(() => {
        assessmentCommentActions.getAllAssessmentCommentAttributesList(commentId);
    }, []);

    return (
        <>
            <div className={classes.header}>
                <Title title='Attribute Scores' />
                <Link to={`${location.pathname}/create-attribute`} className={classes.headerBtn}>
                    Create Score
                </Link>
            </div>
            <TableContainer>
                <Grid container style={{ padding: '1rem 0px' }}>
                    {
                        loading && commentAttributes.length === 0 ?
                            <Box style={{display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress size={72} thickness={3} color="primary"/>
                            </Box> :
                            (commentAttributes && commentAttributes.length === 0 ? (
                                <Typography className={classes.noAttributes}>
                                    No Attribute Scores Found
                                </Typography>
                            ) : (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TableHead className={classes.tableHead}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    className={classes.fullName}
                                                    style={{ textAlign: 'start' }}
                                                >
                                                    title
                                                </Grid>
                                                <Grid item xs={3} className={classes.fullName}>score start</Grid>
                                                <Grid item xs={2} className={classes.fullName}>score end</Grid>
                                                <Grid item xs={2} className={classes.fullName}>color</Grid>
                                                <Grid item xs={2} className={classes.fullName}>edit</Grid>
                                            </Grid>
                                        </TableHead>
                                        {
                                            commentAttributes && commentAttributes.map(item => (
                                                <TableRow className={classes.rowAlignment} key={item._id}>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        className={classes.fullName}
                                                        style={{ textAlign: 'start' }}
                                                    >
                                                        {item.title}
                                                    </Grid>
                                                    <Grid item xs={3} className={classes.dataTableAlignment}>
                                                        {item.score_start}
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.dataTableAlignment}>
                                                        {item.score_end}
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.dataTableAlignment}>
                                                        {getColorName(item.color)}
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.dataTableAlignment}>
                                                        <Link to={`${location.pathname}/update-attribute/${item._id}`}>
                                                            <CreateOutlinedIcon className={classes.iconColor} />
                                                        </Link>
                                                    </Grid>
                                                </TableRow>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            ))
                    }
                </Grid>
            </TableContainer>
        </>
    )
};

let mapStateToProps = (state) => {
    return {
        commentAttributes: state.assessmentComments.getAllAssessmentCommentAttributesData,
        loading: state.ui.assessmentComments.getAssessmentCommentAttributesLoading,
    };
};

export default connect(mapStateToProps)(CommentAttributes);