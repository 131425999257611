import {Container, FormEditor,  Title} from "../shared";
import {connect} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {ActionContext} from "../../contexts";
import {Box, FormControl, FormLabel, Grid, Typography} from "@material-ui/core";
import CircularProgress from "../shared/CircularProgress";
import {Field, Form, Formik} from "formik";
import {object, string} from "yup";
import useStyles from "./styles";
import {useHistory, useParams} from "react-router-dom";
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import {draftToMarkdown, markdownToDraft} from "markdown-draft-js";
import Alert from "@material-ui/lab/Alert";
import {TextField} from "formik-material-ui";
import {GlobalCss} from "../../config/GlobalCss";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import ClearIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
import Button from "../shared/Button";

const CommunicationsForm = (props) => {
    const classes = useStyles();
    const { communication, error, loading } = props;
    const { communicationId } = useParams();
    const { assessmentCommentActions } = useContext(ActionContext);
    const history = useHistory();

    const [descriptionEditorState, setDescriptionEditorState] = useState(EditorState.createEmpty());
    const [doTip1HeadlineEditorState, setDoTip1HeadlineEditorState] = useState(EditorState.createEmpty());
    const [doTip1SummaryEditorState, setDoTip1SummaryEditorState] = useState(EditorState.createEmpty());
    const [doTip2HeadlineEditorState, setDoTip2HeadlineEditorState] = useState(EditorState.createEmpty());
    const [doTip2SummaryEditorState, setDoTip2SummaryEditorState] = useState(EditorState.createEmpty());
    const [dontTip1HeadlineEditorState, setDontTip1HeadlineEditorState] = useState(EditorState.createEmpty());
    const [dontTip1SummaryEditorState, setDontTip1SummaryEditorState] = useState(EditorState.createEmpty());
    const [dontTip2HeadlineEditorState, setDontTip2HeadlineEditorState] = useState(EditorState.createEmpty());
    const [dontTip2SummaryEditorState, setDontTip2SummaryEditorState] = useState(EditorState.createEmpty());
    const [labels, setLabels] = useState([]);
    const [iconName, setIconName] = useState('');
    const [iconString, setIconString] = useState('');
    const [showIcon, setShowIcon] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');


    useEffect(() => {
        if (communicationId) {
            assessmentCommentActions.getCommunicationById(communicationId)
        }
    }, [assessmentCommentActions, communicationId]);

    const convertMarkdownToText = (markDown) => {
        const markToDraft = markdownToDraft(markDown);
        const contentState = convertFromRaw(markToDraft);
        return EditorState.createWithContent(contentState);
    };

    useEffect(() => {
        if (communication) {
            try {
                setDescriptionEditorState(convertMarkdownToText(communication.description));
                setDoTip1HeadlineEditorState(convertMarkdownToText(communication.do_tip_1_headline));
                setDoTip1SummaryEditorState(convertMarkdownToText(communication.do_tip_1_summary));
                setDoTip2HeadlineEditorState(convertMarkdownToText(communication.do_tip_2_headline));
                setDoTip2SummaryEditorState(convertMarkdownToText(communication.do_tip_2_summary));
                setDontTip1HeadlineEditorState(convertMarkdownToText(communication.dont_tip_1_headline));
                setDontTip1SummaryEditorState(convertMarkdownToText(communication.dont_tip_1_summary));
                setDontTip2HeadlineEditorState(convertMarkdownToText(communication.dont_tip_2_headline));
                setDontTip2SummaryEditorState(convertMarkdownToText(communication.dont_tip_2_summary));

                setLabels(communication.labels);
                if (communication.icon) {
                    setShowIcon(communication.icon);
                }
            } catch (err) {
                throw new Error(err)
            }
        }
    }, [communication]);

    let handleErrorClose = () => {
        assessmentCommentActions.resetCommentAttributeError();
    };

    useEffect(() => {
        if (error && error.length > 0) {
            setTimeout(() => {
                handleErrorClose();
            }, 3500);
        }
    }, [error]);

    const convertTextToMarkdown = (text) => {
        const contentState = text.getCurrentContent();
        const rawContent = convertToRaw(contentState);
        return draftToMarkdown(rawContent);
    };

    const handleSubmit = (values) => {

        let data = {
            scale: values.scale,
            description: convertTextToMarkdown(descriptionEditorState),
            do_tip_1_headline: convertTextToMarkdown(doTip1HeadlineEditorState),
            do_tip_1_summary: convertTextToMarkdown(doTip1SummaryEditorState),
            do_tip_2_headline: convertTextToMarkdown(doTip2HeadlineEditorState),
            do_tip_2_summary: convertTextToMarkdown(doTip2SummaryEditorState),
            dont_tip_1_headline: convertTextToMarkdown(dontTip1HeadlineEditorState),
            dont_tip_1_summary: convertTextToMarkdown(dontTip1SummaryEditorState),
            dont_tip_2_headline: convertTextToMarkdown(dontTip2HeadlineEditorState),
            dont_tip_2_summary: convertTextToMarkdown(dontTip2SummaryEditorState),
            icon: iconString,
            labels: labels
        }

        assessmentCommentActions.updateCommunicationById(communicationId, data);
        history.push('/assessment-communications');
    };

    const handleCancel = () => {
        history.push('/assessment-communications');
    };

    const handleUpdateLabels = (value, setFieldValue) => {
        if (value) {
            setLabels([...labels, value]);
            setFieldValue('label', '')
        }
    };

    const handleEditLabel = (index, setFieldValue) => {
        const updateLabel = labels.filter((_, i) => i === index);
        setFieldValue('label', updateLabel);
        const updatedLabels = labels.filter((_, i) => i !== index);
        setLabels(updatedLabels)
    };

    const handleRemoveLabel = (index) => {
        const updatedLabels = labels.filter((_, i) => i !== index);
        setLabels(updatedLabels);
    };

    const handleIconUpload = (event) => {
        const file = event.target.files[0];

        const fileSizeKB = file.size / 1024;
        const fileType = file.type;

        if ((fileType !== 'image/svg+xml' && fileSizeKB > 200) || (fileType === 'image/svg+xml' && fileSizeKB > 200)) {
            setErrorMsg('Icon is larger than 200kbs');
            setIconName('');
            setIconString('');
            if (communication.icon !== '') {
                setShowIcon(communication.icon);
            } else {
                setShowIcon(null);
            }
            return;
        }

        setIconName(file.name);

        const previewIcon = URL.createObjectURL(file);
        setShowIcon(previewIcon);
        setErrorMsg('');

        const reader = new FileReader();
        reader.onloadend = () => {
            setIconString(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handleRemoveIcon = () => {
        setIconName('');
        setIconString('');
        if (communication.icon !== '') {
            setShowIcon(communication.icon);
        } else {
            setShowIcon(null);
        }
    };

    const handleIconDelete = () => {
        setShowIcon(null);
        setIconString('');
    };

    return (
        <Container>
            {
                loading && loading ?
                    <Box style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress size={72} thickness={3} color="primary"/>
                    </Box> : (
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: 'flex',
                                height: '100%',
                                minHeight: '600px',
                                maxHeight: 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <div style={{margin: 'Opx 3rem'}}>
                                <Formik
                                    initialValues={{
                                        scale: communication.scale || '',
                                        icon: '',
                                        labels: ''
                                    }}
                                    validator={object().shape({
                                        scale: string()
                                            .trim()
                                            .matches(
                                                /^[a-zA-Z0-9[\]]+(([',. \-[\]][a-zA-Z0-9 [\]])?[a-zA-Z0-9[\]]*)*$/,
                                                "Enter a valid title"
                                            )
                                            .required('Enter a title'),
                                    })}
                                    onSubmit={(values) => handleSubmit(values)}
                                >
                                    {(formik) => {
                                        let {setFieldValue, values} = formik;

                                        return (
                                            <Form>
                                                <GlobalCss/>
                                                <Title title='Update' text={communication.scale}/>
                                                {error ? (
                                                    <Grid item>
                                                        <Alert severity='error' onClose={handleErrorClose}>
                                                            {error}
                                                        </Alert>
                                                    </Grid>
                                                ) : null}
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} style={{marginTop: '1rem'}}>
                                                        <Grid item xs={12} className={classes.formCard}>
                                                            <Field
                                                                name="scale"
                                                                label="Title"
                                                                placeholder="Title"
                                                                component={TextField}
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                autoFocus
                                                                style={{marginTop: '1rem'}}
                                                            />
                                                            <div style={{marginTop: '0.5rem'}}>
                                                                <FormControl fullWidth>
                                                                    <FormLabel style={{marginBottom: '5px'}}>
                                                                        Description
                                                                    </FormLabel>
                                                                    <FormEditor
                                                                        editorState={descriptionEditorState}
                                                                        onEditorStateChange={setDescriptionEditorState}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className={classes.cardContainer}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            className={classes.formCard}
                                                        >
                                                            <Typography className={classes.cardTitle}>Do Tips 1</Typography>
                                                            <FormControl fullWidth>
                                                                <FormLabel style={{marginBottom: '5px'}}>
                                                                    Headline
                                                                </FormLabel>
                                                                <FormEditor
                                                                    editorState={doTip1HeadlineEditorState}
                                                                    onEditorStateChange={setDoTip1HeadlineEditorState}
                                                                />
                                                            </FormControl>
                                                            <FormControl fullWidth style={{marginTop: '0.5rem'}}>
                                                                <FormLabel style={{marginBottom: '5px'}}>
                                                                    Summary
                                                                </FormLabel>
                                                                <FormEditor
                                                                    editorState={doTip1SummaryEditorState}
                                                                    onEditorStateChange={setDoTip1SummaryEditorState}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            className={classes.formCard}
                                                            style={{marginLeft: '1rem'}}
                                                        >
                                                            <Typography className={classes.cardTitle}>Don't Tips 1</Typography>
                                                            <FormControl fullWidth>
                                                                <FormLabel style={{marginBottom: '5px'}}>
                                                                    Headline
                                                                </FormLabel>
                                                                <FormEditor
                                                                    editorState={dontTip1HeadlineEditorState}
                                                                    onEditorStateChange={setDontTip1HeadlineEditorState}
                                                                />
                                                            </FormControl>
                                                            <FormControl fullWidth style={{marginTop: '0.5rem'}}>
                                                                <FormLabel style={{marginBottom: '5px'}}>
                                                                    Summary
                                                                </FormLabel>
                                                                <FormEditor
                                                                    editorState={doTip1SummaryEditorState}
                                                                    onEditorStateChange={setDoTip1SummaryEditorState}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className={classes.cardContainer}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            className={classes.formCard}
                                                        >
                                                            <Typography className={classes.cardTitle}>Do Tips 2</Typography>
                                                            <FormControl fullWidth>
                                                                <FormLabel style={{marginBottom: '5px'}}>
                                                                    Headline
                                                                </FormLabel>
                                                                <FormEditor
                                                                    editorState={doTip2HeadlineEditorState}
                                                                    onEditorStateChange={setDoTip2HeadlineEditorState}
                                                                />
                                                            </FormControl>
                                                            <FormControl fullWidth style={{marginTop: '0.5rem'}}>
                                                                <FormLabel style={{marginBottom: '5px'}}>
                                                                    Summary
                                                                </FormLabel>
                                                                <FormEditor
                                                                    editorState={doTip2SummaryEditorState}
                                                                    onEditorStateChange={setDoTip2SummaryEditorState}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            className={classes.formCard}
                                                            style={{marginLeft: '1rem'}}
                                                        >
                                                            <Typography className={classes.cardTitle}>Don't Tips 2</Typography>
                                                            <FormControl fullWidth>
                                                                <FormLabel style={{marginBottom: '5px'}}>
                                                                    Headline
                                                                </FormLabel>
                                                                <FormEditor
                                                                    editorState={dontTip2HeadlineEditorState}
                                                                    onEditorStateChange={setDontTip2HeadlineEditorState}
                                                                />
                                                            </FormControl>
                                                            <FormControl fullWidth style={{marginTop: '0.5rem'}}>
                                                                <FormLabel style={{marginBottom: '5px'}}>
                                                                    Summary
                                                                </FormLabel>
                                                                <FormEditor
                                                                    editorState={doTip2SummaryEditorState}
                                                                    onEditorStateChange={setDoTip2SummaryEditorState}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className={classes.formCard}>
                                                            <Typography className={classes.cardTitle}>Labels & Icons</Typography>
                                                            <div className={classes.labelsInput}>
                                                                <Field
                                                                    name="label"
                                                                    label="Labels"
                                                                    placeholder="Labels"
                                                                    component={TextField}
                                                                    variant="outlined"
                                                                    style={{width: '80%'}}
                                                                />
                                                                <button
                                                                    className={classes.btnPrimary}
                                                                    type="button"
                                                                    onClick={() => handleUpdateLabels(values.label, setFieldValue)}
                                                                >
                                                                    Update
                                                                </button>
                                                            </div>
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                            }}>
                                                                {
                                                                    labels && labels.length > 0 ? (
                                                                        labels.map((label, index) => (
                                                                            <div key={index}
                                                                                 className={`${classes.labelItem} ${classes.labelItemSpacing}`}>
                                                                                <div style={{display: 'flex'}}>
                                                                                    <Box
                                                                                        onClick={() => handleEditLabel(index, setFieldValue)}
                                                                                        style={{marginRight: 4}}>
                                                                                        <CreateOutlinedIcon style={{
                                                                                            fontSize: '0.9rem',
                                                                                            marginTop: 1
                                                                                        }}/>
                                                                                    </Box>
                                                                                    {label}
                                                                                </div>
                                                                                <Box
                                                                                    onClick={() => handleRemoveLabel(index)}
                                                                                    style={{marginTop: 'auto'}}>
                                                                                    <ClearIcon
                                                                                        style={{fontSize: '0.9rem'}}/>
                                                                                </Box>
                                                                            </div>
                                                                        ))) : null
                                                                }
                                                            </div>
                                                            <div style={{marginTop: '1rem', marginBottom: '0.5rem'}}>
                                                                <FormLabel>Trait Icon</FormLabel>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}>
                                                                    {
                                                                        showIcon === null ? (
                                                                            <div style={{
                                                                                border: 'dashed 1px #D8D8D8',
                                                                                width: 120,
                                                                                height: 120,
                                                                                backgroundColor: '#f3f3f3',
                                                                                borderRadius: 4,
                                                                            }}/>
                                                                        ) : (
                                                                            <div style={{display: "flex"}}>
                                                                                <img src={showIcon} alt='Icon'
                                                                                     width={120} height={120}/>
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'flex-start'
                                                                                }}>
                                                                                    {
                                                                                        iconName === '' && showIcon ? (
                                                                                            <Box
                                                                                                onClick={handleIconDelete}
                                                                                                style={{
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center'
                                                                                                }}>
                                                                                                <ClearIcon style={{
                                                                                                    fontSize: '1.1rem',
                                                                                                    marginLeft: 15
                                                                                                }}/>
                                                                                            </Box>
                                                                                        ) : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div style={{display: 'flex', paddingTop: 10}}>
                                                                    <FormLabel htmlFor='iconUpload'
                                                                               className={classes.iconBtn}>
                                                                        <div style={{marginTop: 8}}>
                                                                            Upload Icon
                                                                        </div>
                                                                        <input type='file' hidden name='icon'
                                                                               id='iconUpload'
                                                                               onChange={handleIconUpload}/>
                                                                    </FormLabel>
                                                                    <div style={{display: 'flex'}}>
                                                                        <Typography style={{
                                                                            fontSize: '1rem',
                                                                            marginTop: 15,
                                                                            marginLeft: 5,
                                                                        }}>
                                                                            {iconName}
                                                                        </Typography>
                                                                        {
                                                                            iconName.length > 0 ? (
                                                                                <Box onClick={handleRemoveIcon} style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center'
                                                                                }}>
                                                                                    <ClearIcon style={{
                                                                                        fontSize: '1.1rem',
                                                                                        marginLeft: 15
                                                                                    }}/>
                                                                                </Box>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                    {
                                                                        errorMsg.length > 0 ? (
                                                                            <Typography
                                                                                className={classes.error}>{errorMsg}</Typography>
                                                                        ) : (
                                                                            <>
                                                                                {
                                                                                    iconName.length === 0 ? (
                                                                                        <Typography>
                                                                                            Upload a square icon (e.g., 512x512px) and keep the file size under 200KB for best results.
                                                                                        </Typography>
                                                                                    ) : null
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <div className={clsx(classes.content)}>
                                                    <Button
                                                        className={classes.btnSecondary}
                                                        type="button"
                                                        variant="text"
                                                        onClick={handleCancel}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className={classes.btnPrimary}
                                                        type="submit"
                                                    >
                                                        Update
                                                    </Button>
                                                </div>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </Grid>
                    )
            }
        </Container>
    )
};

let mapStateToProps = (state) => {
    return {
        loading: state.ui.assessmentComments.loadingCommunicationById,
        communication: state.assessmentComments.selectedCommunication,
        error: state.ui.assessmentComments.error,
    };
};

export default connect(mapStateToProps)(CommunicationsForm);