import React, {useContext, useEffect, useState} from 'react';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Typography
} from "@material-ui/core";
import { connect } from "react-redux";
import { ActionContext } from "../../../contexts";
import filterDashedLogo from "../../../assets/FilterDashedIcon.svg";
import ClearIcon from "@material-ui/icons/ClearOutlined";
import { useSelector } from "react-redux";
import { accountTypes } from "../../../store/constants";
import {Title} from "../../shared";
import useStyles from "./styles";

function Filter(props) {
    const {
        accountType,
        isFilteredApply,
        pageChange,
        conferenceData,
        sportsData,
        unitData,
        sportUnits,
        sportPositions,
        conferenceByType,
        loadingFilters,
        updateFilter,
        teams,
        teamsByConference,
    } = props;
    let classes = useStyles();
    let actions = useContext(ActionContext);
    let { playerActions, sportsActions, conferenceActions } = actions;
    let [finalSports, setFinalSports] = useState([]);
    let [finalTeams, setFinalTeams] = useState([]);
    let [finalUnits, setFinalUnits] = useState([]);
    let [finalPositions, setFinalPositions] = useState([]);
    let accountState = useSelector((state) => state.account);

    useEffect(() => {
        playerActions.getTeamByName(accountState.team)
        conferenceActions.getConferenceList();
        sportsActions.getAllUnits();
    }, [accountState.team, conferenceActions, playerActions, sportsActions]);

    const [state, setState] = useState({
        conference: "",
        sport: "",
        unit: "",
        position: "",
        athleteType: "All",
        conferenceType: "All",
        attribute: "",
        attributeSort: "",
        team: ""
    });

    useEffect(() => {
        setFinalSports(sportsData);
        setFinalTeams(teams);
        setFinalUnits(sportUnits);
        setFinalPositions(sportPositions);
    }, []);

    useEffect(() => {
        if (state.conference !== '') {
            let conference = conferenceData && conferenceData.find(con => con.name === state.conference);
            if (conference) {
                let sports = sportsData && sportsData.find(sport => sport._id === conference.sport_id);
                conferenceActions.getTeamsByConferenceId(conference._id);
                if (sports) {
                    setFinalSports([sports]);
                }
            }
        }
    }, [conferenceActions, conferenceData, sportsData, state.conference]);

    useEffect(() => {
        if (teamsByConference.length > 0) {
            let conferenceTeams = teamsByConference && teamsByConference.reduce((obj, team) => {
                obj[team.name] = team.name;
                return obj;
            }, {});

            if (conferenceTeams) {
                setFinalTeams(conferenceTeams);
            }
        }
    }, [teamsByConference]);

    useEffect(() => {
        if (state.sport !== '') {
            let sport = sportsData && sportsData.find(sport => sport.name === state.sport);
            sportsActions.getSportUnitDetails(sport._id);
            sportsActions.getSportPositionDetails(sport._id);
        }
    }, [sportsActions, sportsData, state.sport]);

    useEffect(() => {
        if (unitData.length > 0) {
            setFinalUnits(unitData);
        }
    }, [unitData]);

    useEffect(() => {
        if (state.unit) {
            if (sportPositions) {
                setFinalPositions(sportPositions
                    .filter(unit => unit.unit_name === state.unit)
                    .map(position => {
                        return {
                            _id: position._id,
                            position: position.position
                        }
                    }));
            }
        }
    }, [sportPositions, state.unit]);

    const [behavior, setBehavior] = useState('');
    const [behaviorSort, setBehaviorSort] = useState('');

    useEffect(() => {
        if (updateFilter) {
            setState(updateFilter);
            setBehavior(updateFilter.behaviorRisk);
            setBehaviorSort(updateFilter.behaviorRiskSort);
        }
    }, [updateFilter]);

    const handlePlayerChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });

        playerActions.updateFilterValue(event.target.name, event.target.value)
    };

    const handleConferenceChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });

        playerActions.updateFilterValue(event.target.name, event.target.value)
        if (event.target.value === "All") {
            conferenceActions.getConferenceList();
        } else {
            conferenceActions.getConferenceByType(event.target.value);
            setState({
                ...state,
                "conference": "",
            });

            playerActions.updateFilterValue("conference", "")
        }
    };

    const handleSportChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
            "unit": "",
            "position": ""
        });

        playerActions.updateFilterValue(event.target.name, event.target.value)
        playerActions.updateFilterValue("unit", "")
        playerActions.updateFilterValue("position", "")
        sportsActions.resetSportsApi()
    };

    const handleTeamChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });

        playerActions.updateFilterValue(event.target.name, event.target.value)
    };

    const handleUnitChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });

        playerActions.updateFilterValue(event.target.name, event.target.value);
    };

    const handleClearClick = (name) => {
        if (name === "unit") {
            setState({
                ...state,
                "unit": "",
                "position": ""
            });

            playerActions.updateFilterValue("unit", "")
            playerActions.updateFilterValue("position", "")
        } else if (name === "sport") {
            setState({
                ...state,
                "sport": "",
                "unit": "",
                "position": ""
            });

            playerActions.updateFilterValue("sport", "")
            playerActions.updateFilterValue("unit", "")
            playerActions.updateFilterValue("position", "")
        }
        else if (name === "conference") {
            setState({
                ...state,
                "conference": "",
            });

            playerActions.updateFilterValue("conference", "")
        }
        else if (name === "behaviorRisk") {
            setBehavior("")
            setBehaviorSort("")
            playerActions.updateFilterValue("behaviorRisk", "")
            playerActions.updateFilterValue("behaviorRiskSort", "")
        }
        else if (name === "attribute") {
            setState({
                ...state,
                "attribute": "",
                "attributeSort": "",

            });

            playerActions.updateFilterValue("attribute", "")
            playerActions.updateFilterValue("attributeSort", "")
        }
        else if (name === "behaviorRiskSort") {
            setBehaviorSort("")
            playerActions.updateFilterValue("behaviorRiskSort", "")
        }
        else {
            setState({
                ...state,
                [name]: "",
            });
            playerActions.updateFilterValue(name, "")
        }

        if (accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.proSecurity) {
            if (name === "unit") {
                sportsActions.resetSportsApi()
            }
        } else {
            if (name === "sport") {
                sportsActions.resetUnitsApi()
                sportsActions.resetSportsApi()
            } else if (name === "unit") {
                sportsActions.resetSportsApi()
            }
        }
    };

    const handleCancel = () => {
        playerActions.resetFilterValue();
        sportsActions.deselectSport();
        setFinalSports([]);
        setFinalTeams([]);
        setFinalUnits([]);
        setFinalPositions([]);

        if (accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.proSecurity) {
            sportsActions.resetSportsApi();
        } else {
            sportsActions.resetUnitsApi();
            sportsActions.resetSportsApi();
        }

        pageChange('list');
    };

    const handleSave = () => {
        let model = {
            conference: state.conference ? state.conference : '',
            sport: state.sport ? state.sport : '',
            unit: state.unit ? state.unit : '',
            position: state.position ? state.position : '',
            athleteType: state.athleteType === "All" ? '' : state.athleteType,
            conferenceType: state.conferenceType === "All" ? '' : state.conferenceType,
            attribute: state.attribute ? state.attribute : '',
            behavior: behavior ? behavior : '',
            attributeSort: state.attribute_sort ? state.attribute_sort : '',
            behaviorSort: behaviorSort ? behaviorSort : '',
            team : state.team ? state.team : ''
        };

        playerActions.getAllFiltersCandidates(model);
        sportsActions.deselectSport();
    };

    useEffect(() => {
        conferenceActions.getConferenceList();
        sportsActions.getSportsList();
        playerActions.getTeamList();
    }, [conferenceActions, playerActions, sportsActions]);

    useEffect(() => {
        if (loadingFilters === true) {
            pageChange('list')
            isFilteredApply(true)
            playerActions.isFilteredData(true)
        }
    }, [isFilteredApply, loadingFilters, pageChange, playerActions]);

    useEffect(() => {
        if (accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.proSecurity) {

            if (accountState?.sport) {
                playerActions.updateFilterValue("sport", accountState?.sport)
                sportsActions.getSportUnitDetails(accountState?.sport);
            } else {
                playerActions.updateFilterValue("sport", "Football")
                sportsActions.getSportUnitDetails("Football");
            }

        }

    }, [accountState?.sport, accountType, playerActions, sportsActions]);

    const finalConference = state.conferenceType !== "All" ? conferenceByType : conferenceData;

    const handleAttributeChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });

        playerActions.updateFilterValue(event.target.name, event.target.value);
    };

    const handleBehaviorChange = (event) => {
        setBehavior(event.target.value)
        playerActions.updateFilterValue(event.target.name, event.target.value);
    };

    const handleBehaviorSortChange = (event) => {
        setBehaviorSort(event.target.value)
        playerActions.updateFilterValue(event.target.name, event.target.value);
    };

    const attributesTypes = {
        Character: 'Character',
        Coachability: 'Coachability',
        Composure: 'Composure',
        Leadership: 'Leadership'
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={8} spacing={3} className={classes.filterContainer}>
                <div style={{margin: '0rem 3rem'}}>
                    <Title title='Blue Chip Filter' style={{margin: '1rem 0rem'}} />
                    <Typography className={classes.subtitle}>
                        Select all that are necessary
                    </Typography>
                    <Grid item xs={12}>
                        {
                            (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmChiefPsychologist || accountType === accountTypes.mmPsychologist || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach) ?
                                <div className={classes.radioBtnContainer}>
                                    <Grid item xs={12}>
                                        <FormControl>
                                            <FormLabel
                                                id="demo-radio-buttons-group-label"
                                                className={classes.radioBtn}
                                            >
                                                League
                                            </FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue=""
                                                row
                                                name="conferenceType"
                                                value={state.conferenceType}
                                                onChange={handleConferenceChange}
                                            >
                                                <FormControlLabel value="All" control={<Radio/>} label="All"/>
                                                <FormControlLabel value="Professional" control={<Radio/>} label="Professional"/>
                                                <FormControlLabel value="Collegiate" control={<Radio/>} label="Collegiate"/>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </div>
                                :
                                ""
                        }
                        {
                            (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.colAthleticDirector || accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist || accountType === accountTypes.mmChiefPsychologist || accountType === accountTypes.proSecurity
                            ) ?
                                <div className={classes.radioBtnContainer}>
                                    <FormControl>
                                        <FormLabel
                                            id="demo-radio-buttons-group-label"
                                            className={classes.radioBtn}
                                        >
                                            Athlete Type
                                        </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue=""
                                            row
                                            name="athleteType"
                                            value={state.athleteType}
                                            onChange={handlePlayerChange}
                                        >
                                            <FormControlLabel value="All" control={<Radio/>} label="All"/>
                                            <FormControlLabel value="Roster" control={<Radio/>} label="Roster"/>
                                            <FormControlLabel value="Recruit" control={<Radio/>} label="Recruit"/>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                :
                                ""
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '0.5rem',
                        }}
                    >
                        {
                            (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist) ?
                                <Grid item xs={6}>
                                    <select
                                        className={classes.dropdown}
                                        value={state.conference}
                                        onChange={handlePlayerChange}
                                        name="conference"
                                        endAdornment={
                                            state.conference !== "" ?
                                                <IconButton
                                                    sx={{visibility: state.conference !== "" ? "visible" : "hidden"}}
                                                    onClick={() => handleClearClick("conference")}><ClearIcon/>
                                                </IconButton>
                                                :
                                                ""
                                        }
                                    >
                                        <option value="" disabled selected hidden>Conference</option>
                                        {
                                            finalConference && finalConference.map(list =>
                                                <option
                                                    className={classes.dropdownOptions}
                                                    key={list.name}
                                                    value={list.name}>
                                                    {list.name}
                                                </option>
                                            )
                                        }
                                    </select>
                                </Grid>
                                :
                                ""
                        }
                        {
                            (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.colAthleticDirector || accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist || accountType === accountTypes.mmChiefPsychologist) ?

                                <Grid item xs={6}>
                                    <select
                                        className={classes.dropdown}
                                        id="demo-simple-select-autowidth"
                                        value={state.sport}
                                        onChange={handleSportChange}
                                        name="sport"
                                        endAdornment={
                                            state.sport !== "" ?
                                                <IconButton
                                                    sx={{visibility: state.sport !== "" ? "visible" : "hidden"}}
                                                    onClick={() => handleClearClick("sport")}><ClearIcon/>
                                                </IconButton>
                                                :
                                                ""
                                        }
                                    >
                                        <option value="" disabled selected hidden>Sport</option>
                                        {
                                            finalSports && finalSports.map(list =>
                                                <option
                                                    className={classes.dropdownOptions}
                                                    key={list.name}
                                                    value={list.name}
                                                >
                                                    {list.name}
                                                </option>
                                            )
                                        }
                                    </select>
                                </Grid>
                                :
                                ""
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '0.5rem',
                        }}
                    >
                        {
                            (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist) ?
                                <Grid item xs={6}>
                                    <select
                                        className={classes.dropdown}
                                        value={state.team}
                                        onChange={handleTeamChange}
                                        name="team"
                                        endAdornment={
                                            state.team !== "" ?
                                                <IconButton
                                                    sx={{visibility: state.team !== "" ? "visible" : "hidden"}}
                                                    onClick={() => handleClearClick("team")}><ClearIcon/>
                                                </IconButton>
                                                :
                                                ""
                                        }
                                    >
                                        <option value="" disabled selected hidden>Team</option>
                                        {
                                            finalTeams && Object.keys(finalTeams).map(list =>
                                                <option
                                                    className={classes.dropdownOptions}
                                                    key={list}
                                                    value={list}>
                                                    {list}
                                                </option>
                                            )
                                        }
                                    </select>
                                </Grid>
                                :
                                ""
                        }
                        {
                            (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.colAthleticDirector || accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.mmChiefPsychologist || accountType === accountTypes.proSecurity
                            ) ?
                                <Grid item xs={6}>
                                    <select
                                        className={classes.dropdown}
                                        value={state.unit}
                                        onChange={handleUnitChange}
                                        name="unit"
                                        endAdornment={
                                            state.unit !== "" ?
                                                <IconButton
                                                    sx={{visibility: state.unit !== "" ? "visible" : "hidden"}}
                                                    onClick={() => handleClearClick("unit")}><ClearIcon/>
                                                </IconButton>
                                                :
                                                ""
                                        }
                                    >
                                        <option value="" disabled selected hidden>Unit</option>
                                        {
                                            finalUnits && finalUnits.map(list =>
                                                <option
                                                    className={classes.dropdownOptions}
                                                    key={list._id}
                                                    value={list.unit}
                                                >
                                                    {list.unit}
                                                </option>
                                            )
                                        }
                                    </select>
                                </Grid>
                                :
                                ""
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '0.5rem',
                        }}
                    >
                        {
                            (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.colAthleticDirector || accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.mmChiefPsychologist || accountType === accountTypes.proSecurity
                            ) ?
                                <Grid item xs={12}>
                                    <select
                                        className={classes.dropdown}
                                        value={state.position}
                                        onChange={handlePlayerChange}
                                        name="position"
                                        endAdornment={
                                            state.position !== "" ?
                                                <IconButton
                                                    sx={{visibility: state.position !== "" ? "visible" : "hidden"}}
                                                    onClick={() => handleClearClick("position")}><ClearIcon/>
                                                </IconButton>
                                                :
                                                ""
                                        }
                                        style={{width: '99%'}}
                                    >
                                        <option value="" disabled selected hidden>Position</option>
                                        {
                                            finalPositions && finalPositions.map(list =>
                                                <option
                                                    className={classes.dropdownOptions}
                                                    key={list.position}
                                                    value={list.position}
                                                >
                                                    {list.position}
                                                </option>
                                            )
                                        }
                                    </select>
                                </Grid>
                                :
                                ""
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '0.5rem',
                        }}
                    >
                        {
                            (accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist) ?
                                <Grid item xs={6}>
                                    <select
                                        className={classes.dropdown}
                                        value={state.attribute}
                                        onChange={handleAttributeChange}
                                        name="attribute"
                                        endAdornment={
                                            state.attribute !== "" ?
                                                <IconButton
                                                    sx={{visibility: state.attribute !== "" ? "visible" : "hidden"}}
                                                    onClick={() => handleClearClick("attribute")}><ClearIcon/>
                                                </IconButton>
                                                :
                                                ""
                                        }
                                    >
                                        <option value="" disabled selected hidden>Attributes</option>
                                        {
                                            attributesTypes && Object.keys(attributesTypes).map(list =>
                                                <option
                                                    className={classes.dropdownOptions}
                                                    key={list}
                                                    value={list}
                                                >
                                                    {list}
                                                </option>
                                            )
                                        }
                                    </select>
                                </Grid>
                                :
                                ""
                        }
                        {
                            (accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist) ?
                                <Grid item xs={6}>
                                    <FormControl variant="filled" sx={{m: 1, minWidth: 80}}>
                                        <InputLabel id="demo-simple-select-autowidth-label">Attribute
                                            Sort</InputLabel>
                                        <Select
                                            style={{width: "200px"}}
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={state.attributeSort}
                                            onChange={handleAttributeChange}
                                            autoWidth
                                            label="Attribute"
                                            name="attributeSort"
                                            endAdornment={
                                                state.attributeSort !== "" ?
                                                    <IconButton
                                                        sx={{visibility: state.attributeSort !== "" ? "visible" : "hidden"}}
                                                        onClick={() => handleClearClick("attributeSort")}><ClearIcon/>
                                                    </IconButton>
                                                    :
                                                    ""
                                            }
                                        >
                                            <MenuItem value='High to Low'>High to Low</MenuItem>
                                            <MenuItem value='Low to High'>Low to High</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                :
                                ""
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '0.5rem',
                        }}
                    >
                        {
                            (accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist) ?
                                <Grid item xs={6}>
                                    <FormControl variant="filled" sx={{m: 1, minWidth: 80}}>
                                        <InputLabel id="demo-simple-select-autowidth-label">Behavior
                                            Risk</InputLabel>
                                        <Select
                                            style={{width: "200px"}}
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={behavior}
                                            onChange={handleBehaviorChange}
                                            autoWidth
                                            label="BehaviorRisk"
                                            name="behaviorRisk"
                                            endAdornment={
                                                behavior !== "" ?
                                                    <IconButton
                                                        sx={{visibility: behavior !== "" ? "visible" : "hidden"}}
                                                        onClick={() => handleClearClick("behaviorRisk")}><ClearIcon/>
                                                    </IconButton>
                                                    :
                                                    ""
                                            }
                                        >
                                            <MenuItem value='Aggression/Violence'>Aggression/Violence</MenuItem>
                                            <MenuItem value='Delinquent Behavior'>Delinquent Behavior</MenuItem>
                                            <MenuItem value='Drug/Alcohol'>Drug & Alcohol</MenuItem>
                                            <MenuItem value='Emotional Distress'>Emotional Distress</MenuItem>
                                            <MenuItem value='Sexual Behavior'>Sexual Behavior</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                :
                                ""
                        }
                        {
                            (accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist) ?

                                <Grid item xs={6}>
                                    <FormControl variant="filled" sx={{m: 1, minWidth: 80}}>
                                        <InputLabel id="demo-simple-select-autowidth-label">Behavior Risk
                                            Sort</InputLabel>
                                        <Select
                                            style={{width: "200px"}}
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={behaviorSort}
                                            onChange={handleBehaviorSortChange}
                                            autoWidth
                                            label="BehaviorRisk"
                                            name="behaviorRiskSort"
                                            endAdornment={
                                                behaviorSort !== "" ?
                                                    <IconButton
                                                        sx={{visibility: behaviorSort !== "" ? "visible" : "hidden"}}
                                                        onClick={() => handleClearClick("behaviorRiskSort")}><ClearIcon/>
                                                    </IconButton>
                                                    :
                                                    ""
                                            }
                                        >
                                            <MenuItem value='High to Low'>High to Low</MenuItem>
                                            <MenuItem value='Low to High'>Low to High</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                :
                                ""
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className={classes.content}
                    >
                        <Button className={classes.btnSecondary} onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button className={classes.btnPrimary} onClick={handleSave}>
                            Run Report
                        </Button>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={4} >
                <div style={{display: 'flex', alignItems: 'center', height: '100%', maxWidth: 300}}>
                    <img
                        src={filterDashedLogo}
                        alt="logo"
                    />
                </div>
            </Grid>
        </Grid>
    )
}

let mapStateToProps = (state) => {
    return {
        conferenceData: state.conference.allConferenceList,
        conferenceByType: state.conference.allConferenceByType,
        sportsData: state.sports.sportsListData,
        teams: state.ui.player.candidateTeams,
        teamsByConference: state.conference.teamByConference,
        unitData: state.sports.unitsBySportId,
        sportUnits: state.sports.sportUnits,
        sportPositions: state.sports.sportPositions,
        loadingFilters: state.ui.player.loadingFilters,
        updateFilter: state.player.updateFilter,
        isFiltered: state.player.isFiltered,
        accountType: state.account.accountType,
    };
};

export default connect(mapStateToProps)(Filter);