import {connect} from "react-redux";
import {Container, FormEditor, RunDashedLogo, Title} from "../../shared";
import {Box, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Typography} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import {number, object, string} from "yup";
import {GlobalCss} from "../../../config/GlobalCss";
import Alert from "@material-ui/lab/Alert";
import {ActionContext} from "../../../contexts";
import {Select, TextField} from "formik-material-ui";
import clsx from "clsx";
import Button from "../../shared/Button";
import {Link, useHistory, useParams} from "react-router-dom";
import {snackbarActions} from "../../shared/Snackbar";
import useStatusColor from "../../../utils/useStatusSelector";
import {EditorState, convertToRaw} from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './styles';

const CreateCommentAttribute = (props) => {
    const classes = useStyles();
    const {assessmentCommentActions} = useContext(ActionContext);
    const {error} = props;
    const {commentId} = useParams()
    const history = useHistory();
    const { getHexValue } = useStatusColor();
    let [descriptionEditorState, setDescriptionEditorState] = useState(EditorState.createEmpty());
    let [actionEditorState, setActionEditorState] = useState(EditorState.createEmpty());
    let [iconName, setIconName] = useState('');
    let [iconString, setIconString] = useState('');
    let [showIcon, setShowIcon] = useState(null);
    let [errorMsg, setErrorMsg] = useState('');

    let handleErrorClose = () => {
        assessmentCommentActions.resetCommentAttributeError();
    };

    useEffect(() => {
        if (error && error.length > 0) {
            setTimeout(() => {
                handleErrorClose();
            }, 3500);
        }
    }, [error]);

    const colorList = [
        'Dark Blue',
        'Yellow',
        'Blue',
        'Light Blue',
        'Green'
    ];

    const renderError = (error) => {
        if (typeof error === 'string') {
            return error;
        } else if (Array.isArray(error)) {
            return error.join(', ');
        } else if (typeof error === 'object' && error !== null) {
            return JSON.stringify(error);
        }
        return '';
    };

    let [labels, setLabels] = useState([]);

    const handleAddLabels = (value, setFieldValue) => {
        if (value) {
            setLabels([...labels, value]);
            setFieldValue('label', '')
        }
    };

    const handleRemoveLabel = (index) => {
        const updatedLabels = labels.filter((_, i) => i !== index);
        setLabels(updatedLabels);
    };

    const handleFormSubmit = ( values ) => {
        const descriptionContentState = descriptionEditorState.getCurrentContent();
        const descriptionRawContent = convertToRaw(descriptionContentState);
        const descriptionMarkdown = draftToMarkdown(descriptionRawContent);

        const actionContentState = actionEditorState.getCurrentContent();
        const actionRawContent = convertToRaw(actionContentState);
        const actionStatementMarkdown = draftToMarkdown(actionRawContent);

        let data = {
            title: values.title,
            description: descriptionMarkdown,
            action_statement: actionStatementMarkdown,
            labels: labels,
            score_start: values.score_start,
            score_end: values.score_end,
            color: getHexValue(values.color),
            icon: iconString,
        };

        assessmentCommentActions.createCommentAttribute(commentId, data);

        snackbarActions.showSnackbar(
            'Comment attribute was successfully created.'
        );

        history.push(`/assessment-comment/update/${commentId}`)
    };

    const handleIconUpload = (event) => {
        const file = event.target.files[0];

        const fileSizeKB = file.size / 1024;
        const fileType = file.type;

        if ((fileType !== 'image/svg+xml' && fileSizeKB > 200) || (fileType === 'image/svg+xml' && fileSizeKB > 200)) {
            setErrorMsg('Icon is larger than 200kbs');
            setIconName('');
            setShowIcon(null);
            setIconString('');
            return;
        }

        setIconName(file.name);

        const previewIcon = URL.createObjectURL(file);
        setShowIcon(previewIcon);
        setErrorMsg('');

        const reader = new FileReader();
        reader.onloadend = () => {
            setIconString(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handleRemoveIcon = () => {
        setIconName('');
        setShowIcon(null);
        setIconString('');
    };

    return (
        <Container>
        <Grid container spacing={1}>
            <Grid item xs={8} style={{ height: '100%', minHeight: '600px', maxHeight: 'auto' }}>
                <div style={{margin: '0rem 3rem'}}>
                    <Formik
                        initialValues={{
                            title: '',
                            description: '',
                            action_statement: '',
                            label: '',
                            score_start: 0,
                            score_end: 0,
                            color: '',
                        }}
                        validator={object().shape({
                            title: string()
                                .trim()
                                .matches(
                                    /^[a-zA-Z0-9\[\]]+(([',. \-\[\]][a-zA-Z0-9 \[\]])?[a-zA-Z0-9\[\]]*)*$/,
                                    "Enter a valid title"
                                )
                                .required('Enter a title'),
                            score_start: number().nullable().required('Select a starting score'),
                            score_end: number().nullable().required('Select a end score'),
                            color: string().nullable().required('Select a color'),
                            icon: ''
                        })}
                        onSubmit={(values) => {
                            handleFormSubmit(values)
                        }}
                    >
                        {(formik) => {
                            let {errors, setFieldValue, touched, values} = formik;

                            return (
                                <Form autoComplete='off'>
                                    <GlobalCss />
                                    <div className={classes.form}>
                                        <Title title="Create Score"/>
                                        {error ? (
                                            <Grid item>
                                                <Alert severity='error' onClose={handleErrorClose}>
                                                    {error}
                                                </Alert>
                                            </Grid>
                                        ) : null}
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Field
                                                    name="title"
                                                    label="Title"
                                                    placeholder="Title"
                                                    component={TextField}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    autoFocus
                                                    style={{marginTop: '1rem'}}
                                                />
                                            </Grid>
                                            <Grid item xs={12}
                                                  style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <Field
                                                    name="score_start"
                                                    label="Starting score"
                                                    placeholder="Starting score"
                                                    component={TextField}
                                                    type='number'
                                                    variant="outlined"
                                                    required
                                                    autoFocus
                                                    style={{width: '31%'}}
                                                />
                                                <Field
                                                    name="score_end"
                                                    label="End score"
                                                    placeholder="End score"
                                                    component={TextField}
                                                    type='number'
                                                    variant="outlined"
                                                    required
                                                    autoFocus
                                                    style={{width: '31%'}}
                                                />
                                                <FormControl variant="outlined" style={{display: 'flex', width: '34%'}}>
                                                    <InputLabel htmlFor="color-label">
                                                        Color *
                                                    </InputLabel>
                                                    <Field
                                                        component={Select}
                                                        name="color"
                                                        label="Color *"
                                                        placeholder="Color *"
                                                        inputProps={{
                                                            id: "color",
                                                        }}
                                                        required
                                                        renderValue={(selected) => (
                                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ backgroundColor: getHexValue(selected), width: 10, height: 10, marginRight: 10 }} />
                                                                {selected}
                                                            </Box>
                                                        )}
                                                    >
                                                        {colorList && colorList.map((list) => (
                                                            <MenuItem key={list} value={list}>
                                                                <Box style={{ backgroundColor: getHexValue(list), width: 10, height: 10, marginRight: 10 }} />
                                                                {list}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                    {touched["sport"] && !!errors["sport"] && (
                                                        <FormHelperText>
                                                            {renderError(errors["sport"])}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <FormLabel style={{marginBottom: '5px'}}>Description</FormLabel>
                                                    <FormEditor editorState={descriptionEditorState} onEditorStateChange={setDescriptionEditorState} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <FormLabel style={{marginBottom: '5px'}}>Action Statement</FormLabel>
                                                    <FormEditor editorState={actionEditorState} onEditorStateChange={setActionEditorState} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className={classes.labelsInput}>
                                                    <Field
                                                        name="label"
                                                        label="Labels"
                                                        placeholder="Labels"
                                                        component={TextField}
                                                        variant="outlined"
                                                        style={{width: '65%'}}
                                                    />
                                                    <button
                                                        className={classes.btnPrimary}
                                                        type="button"
                                                        onClick={() => handleAddLabels(values.label, setFieldValue)}
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                                <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                                                    {
                                                        labels && labels.length > 0 ? (
                                                            labels.map((label, index) => (
                                                                <div key={index} className={`${classes.labelItem} ${classes.labelItemSpacing}`}>
                                                                    {label}
                                                                    <Box onClick={() => handleRemoveLabel(index)} style={{marginTop: 'auto'}}>
                                                                        <ClearIcon style={{fontSize: '0.9rem'}}/>
                                                                    </Box>
                                                                </div>
                                                            ))
                                                        ) : null
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormLabel>Trait Icon</FormLabel>
                                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '97%'}}>
                                                    {
                                                        showIcon === null ? (
                                                            <div style={{
                                                                border: 'dashed 1px #D8D8D8',
                                                                width: 120,
                                                                height: 120,
                                                                backgroundColor: '#f3f3f3',
                                                                borderRadius: 4,
                                                            }}/>
                                                        ) : (
                                                            <img src={showIcon} alt='Icon' width={120} height={120}/>
                                                        )
                                                    }
                                                </div>
                                                <div style={{display: 'flex', paddingTop: 10}}>
                                                    <FormLabel htmlFor='iconUpload' className={classes.iconBtn}>
                                                        <div style={{marginTop: 8}}>
                                                            Upload Icon
                                                        </div>
                                                        <input type='file' hidden name='icon' id='iconUpload' onChange={handleIconUpload}/>
                                                    </FormLabel>
                                                    <div style={{display: 'flex'}}>
                                                        <Typography style={{
                                                            fontSize: '1rem',
                                                            marginTop: 15,
                                                            marginLeft: 5,
                                                        }}>
                                                            {iconName}
                                                        </Typography>
                                                        {
                                                            iconName.length > 0 ? (
                                                                <Box onClick={handleRemoveIcon} style={{display: 'flex', alignItems: 'center'}}>
                                                                    <ClearIcon style={{fontSize: '1.1rem', marginLeft: 15}}/>
                                                                </Box>
                                                            ) : null
                                                        }
                                                    </div>
                                                    {
                                                        errorMsg.length > 0 ? (
                                                            <Typography className={classes.error}>{errorMsg}</Typography>
                                                        ) : null
                                                    }
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div className={clsx(classes.content)}>
                                            <Link
                                                to={`/assessment-comment/update/${commentId}`}
                                                className={classes.btnSecondary}
                                            >
                                                Cancel
                                            </Link>
                                            <Button
                                                className={classes.btnPrimary}
                                                type="submit"
                                            >
                                                Create
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </Grid>
            <Grid item xs={4}>
                <RunDashedLogo />
            </Grid>
        </Grid>
    </Container>
    )
};

let mapStateProps = (state) => {
    return {
        error: state.ui.assessmentComments.error,
    }
};

export default connect(mapStateProps)(CreateCommentAttribute);