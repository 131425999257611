import {makeStyles} from "@material-ui/core";

let useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    container: {
        flexGrow: 1,
        display: "flex",
        margin: "0px",
        backgroundColor: theme.palette.common.white,
        boxShadow: "3px 3px 10px 3px #cdcdcd",
        borderRadius: 8,
    },
    sideBarTitle: {
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: '1rem',
        fontWeight: 700,
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
    },
    selectedSideBarTitle: {
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: '1rem',
        fontWeight: 700,
        textTransform: 'capitalize',
        color: theme.palette.primary.main,
        backgroundColor: theme.colors.veryLightGrey,
        minWidth: 150,
    },
    indicator: {
        backgroundColor: theme.palette.primary.main,
    },
    tabPanel: {
        width: '85%',
        paddingBottom: '2rem',
        borderRadius: "0px 8px 8px 0px",
    },
    overviewContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        margin: "0rem 2rem",
        width: "auto",
    },
    content: {
        backgroundColor: theme.colors.veryLightGrey,
        width: '90%',
        alignSelf: 'center',
        marginBottom: '1rem',
        borderRadius: 5,
    },
    risk: {
        display: "flex",
        justifyContent: "center",
        margin: "0rem 2rem",
        width: "auto",
    },
    brain: {
        fontFamily: theme.fontFamily.prometo,
        fontSize: 15,
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0rem 2rem",
        width: "auto",
        height: "20vh",
        color: theme.palette.primary.main,
    },
}));

export default useStyles;