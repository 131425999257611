import apiHandler, {getAuthHeader} from "./apiHandler";

export const getAllAssessmentComments = async () => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.get('/assessment_comments', {
            headers,
        });

        if (response.data.status !== 'Success') {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.comments;
    } catch (err) {
        throw new Error(err);
    }
};

export const getAssessmentCommentById = async (id) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.get(`/assessment_comment/${id}`, { headers });

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.comment;
    } catch (err) {
        throw new Error(err);
    }
};

export const postAssessmentComment = async (data) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.post('/assessment_comment', data, { headers });

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.comments;
    } catch (err) {
        throw new Error(err);
    }
};

const updateAssessmentComment = async (id, data) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.put(`/assessment_comment/${id}`, data, { headers });

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.comments;
    } catch (err) {
        throw new Error(err);
    }
};

const deleteAssessmentComment = async (id) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.delete(`/assessment_comment/${id}`, { headers });

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.comments;
    } catch (err) {
        throw new Error(err);
    }
};

// Assessment Comment Attributes
const getAssessmentCommentAttributes = async (id) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.get(`/assessment_comment/${id}/attributes`, { headers });

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.attributes
    } catch (err) {
        throw new Error(err);
    }
};

const getAttributeById = async (id) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.get(`/assessment_comment/attribute/${id}`, { headers });

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.attribute
    } catch (e) {
       throw new Error(e);
    }
};

const createCommentAttribute = async (id, data) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.post(`/assessment_comment/${id}/attribute`, data, { headers });

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.attributes;
    } catch (err) {
        throw new Error(err);
    }
};

const updateCommentAttribute = async (id, data) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.put(`/assessment_comment_attribute/${id}`, data, {headers});

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.attributes;
    } catch (err) {
        throw new Error(err);
    }
};

const deleteCommentAttribute = async (id) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.delete(`/assessment_comment_attribute/${id}`, {headers});

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.attributes;
    } catch (err) {
        throw new Error(err);
    }
};

const assessmentCommentColors = async () => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.get("/assessment_score_colors",{headers});

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.colors;
    } catch (err) {
        throw new Error(err);
    }
};

const getCommentSubAttributes = async (commentId) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.get(`/assessment_comment_sub_attributes/${commentId}`, {headers});

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.sub_attributes;
    } catch (err) {
        throw  new Error(err)
    }
};

const getSubAttributeById = async (attributeId) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.get(`/comment_sub_attribute_by_id/${attributeId}`, {headers});

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.sub_attribute;
    } catch (err) {
        throw new Error(err);
    }
};

const createSubAttribute = async (data) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.post('/comment_sub_attribute', data, {headers});

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }
    } catch (err) {
        throw new Error(err);
    }
};

const updateSubAttribute = async (subAttributeId, data) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.put(`/update_comment_sub_attribute/${subAttributeId}`, data, {headers});

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }
    } catch (err) {
        throw new Error(err);
    }
};

const assessmentAttributeResults = async (attribute, age, sport, score) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.get(`/assessment_comment/${attribute}/result?age=${age}&sport=${sport}&score=${score}`, {headers})

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.comment;
    } catch (err) {
        throw new Error(err);
    }
};

export const allCommunications = async () => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.get('/assessment_communications', { headers });

        if (response.data.status !== "Success") {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.communications;
    } catch (err) {
        throw new Error(err);
    }
};

export const getCommunicationById = async (id) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.get(`/assessment_communication/${id}`, { headers });

        if (response.data.status !== 'Success') {
            const err = new Error(response.data.notification);
            return Promise.reject(err);
        }

        return response.data.info.communication;
    } catch (err) {
        throw new Error(err);
    }
};

export const updateCommunicationById = async (id, data) => {
  try {
      const headers = getAuthHeader();
      const response = await apiHandler.put(`/update_assessment_communication/${id}`, data, { headers });

      if (response.data.status !== 'Success') {
          const err = new Error(response.data.notification);
          return Promise.reject(err);
      }
  }  catch (err) {
      throw new Error(err);
  }
};

const  assessmentComments = {
  getAllAssessmentComments,
  getAssessmentCommentById,
  postAssessmentComment,
  updateAssessmentComment,
  deleteAssessmentComment,
  getAssessmentCommentAttributes,
  getAttributeById,
  createCommentAttribute,
  updateCommentAttribute,
  deleteCommentAttribute,
  assessmentCommentColors,
  getCommentSubAttributes,
  getSubAttributeById,
  createSubAttribute,
  updateSubAttribute,
  assessmentAttributeResults,
  allCommunications,
  getCommunicationById,
  updateCommunicationById,
};

export default assessmentComments;
