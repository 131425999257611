import React, {useContext, useEffect, useState} from 'react'
import {
    Divider,
    makeStyles,
    fade,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { ActionContext } from "../../../../contexts";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from "modules/shared/CircularProgress";
import NoData from '../../../../assets/NoData.svg';
import {BackArrow, HeaderButton, Title} from "../../../shared";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import {accountTypes} from "../../../../store/constants";

let useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: `calc(100vh - 50px)`,
        zIndex: theme.zIndex.appBar,
        backgroundColor: theme.colors.veryLightGrey,
    },
    container: {
        padding: 16,
        width: '100%',
        marginLeft: -30,
    },
    placeholder: {
        display: "block",
        width: '100%',
        textAlign: 'center',
        margin: '1rem 0rem',
        justifyContent: "center",
        alignItems: " center",
    },
    positionUI: {
        fontSize: '14px',
        padding: '0.5rem',
        marginBottom: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '0.3rem',
        backgroundColor: theme.colors.lightGrey,
        width: '100%',
    },
    search: {
        justifyContent: 'space-between',
        display: 'flex',
        position: 'relative',
        marginBottom: '14px',
        marginRight: '0px',
        backgroundColor: fade(theme.palette.common.white, 0.15),
        marginLeft: 0,
        width: '100%',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        paddingBottom: '10px',
    },
    btnPrimary: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '13px',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.colors.primaryNavy,
        fontWeight: 700,
        cursor: 'pointer',
        textTransform: 'uppercase',
    },
    accordionSummary:{
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.robotoCondensed,
        backgroundColor: theme.colors.veryLightGrey,
        fontSize: '1.2rem',
        fontWeight: 700,
        borderRadius: 7,
    },
}));

function UnitDetails(props) {
    let classes = useStyles();
    const { accountType, positionLoading, setOpen, sportId, sportPositions, sportType, units } = props;
    let actions = useContext(ActionContext);
    let { sportsActions } = actions;
    const [expandedId, setExpandedId] = useState(0);
    const [editUnitById, setEditUnitById] = useState(0);

    useEffect(() => {
        sportsActions.getSportUnitDetails(sportId);
        sportsActions.getSportPositionDetails(sportId);
    }, [sportId, sportsActions]);

    function handleChangeAccordion(id) {
        if (expandedId !== id) {
            setExpandedId(id)
        } else {
            setExpandedId(0)
        }
    }

    const handleRedirect = () => {
        sportsActions.selectSportsPage("list")
        sportsActions.selectSportsType(null);
    };

    useEffect(() => {
        return () => {
            sportsActions.selectSportsPage("list")
            sportsActions.selectSportsType(null);
        };
    }, [sportsActions]);

    const positions = sportPositions.map(position => {
        return {
            id: position._id,
            position: position.position,
            unitId: position.unitId,
        };
    });

    const handleCreate = (page) => {
        sportsActions.deselectSport();
        sportsActions.deselectPosition();
        sportsActions.deselectSportUnit();
        sportsActions.selectSport(sportId);
        sportsActions.selectedFormName(page);
        sportsActions.selectedFormState('create');
        setOpen(true);
    };

    const handleUpdate = (id, page) =>  {
        setEditUnitById(0);
        if (editUnitById !== id) {
            setEditUnitById(id)
            sportsActions.getUnitById(id);
            sportsActions.selectSportUnit(id);
            sportsActions.getSportPositionById(id);
            sportsActions.selectSport(sportId);
            sportsActions.selectedFormName(page);
            sportsActions.selectedFormState('update');
            setOpen(true);
        }
    };

    return (
        <>
            <BackArrow onClick={handleRedirect} />
            <div className={classes.search}>
                <Title title={`${sportType} Units`} />
                <div style={{display: 'flex'}}>
                    <HeaderButton
                        title='Create Unit'
                        onclick={() => handleCreate('unit')}
                    />
                    <HeaderButton
                        title='Create Position'
                        onclick={() => handleCreate('position')}
                    />
                </div>
            </div>
            <Grid>
                <div style={{padding: 8, height: "100%"}}>
                    {
                        sportPositions.length === 0 ?
                            <div className={classes.placeholder}>
                                <img src={NoData} alt='No Data' style={{width: '11rem'}}/>
                                <div style={{color: '#e61e21'}}> No sport unit found...</div>
                            </div>
                            :
                            <Grid spacing={2} justifyContent="center">
                                {positionLoading ?
                                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                        <CircularProgress size={72} thickness={3} color="primary"/>
                                    </div>
                                    :
                                    units && units.map((result) => (
                                        <Grid item xs={12} key={result.unit} justifyContent="center">
                                            <Accordion
                                                style={{margin: '0px 0px 10px 0px', width: '100%'}}
                                                expanded={expandedId === result._id}
                                                key={`${result._id}`}>
                                                <AccordionSummary
                                                    className={classes.accordionSummary}
                                                    expandIcon={
                                                        <ExpandMoreIcon className={classes.ExpandIcon}/>
                                                    }
                                                    aria-controls="panel1c-content"
                                                    id="panel1c-header"
                                                >
                                                    {accountType === accountTypes.superAdmin ? (
                                                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                                            <div onClick={() => handleChangeAccordion(result._id)}>
                                                                {result.unit}
                                                            </div>
                                                            <div onClick={() => handleUpdate(result._id, 'unit')}>
                                                                <CreateOutlinedIcon/>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                width: '100%',
                                                            }}
                                                             onClick={() => handleChangeAccordion(result._id)}
                                                        >
                                                            {result.unit}
                                                        </div>
                                                    )}
                                                </AccordionSummary>
                                                <Divider/>
                                                <AccordionDetails style={{display: 'block'}}>
                                                    {
                                                        positionLoading ?
                                                            <CircularProgress size={72} thickness={3} color="primary"/>
                                                            :
                                                            positions && positions.length === 0 ?
                                                                <div style={{padding: 8, height: "100%", width: '100%'}}>
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            alignItems: "center",
                                                                            justifyItems: "center",
                                                                            marginTop: 200,
                                                                        }}
                                                                    >
                                                                        <Typography variant="body1">
                                                                            No position found...
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                                :
                                                                positions && positions.map((list) => (
                                                                    list.unitId === result._id ? (
                                                                        <Grid item xs={12} key={list.id} container
                                                                              justifyContent="center">
                                                                            <span style={{
                                                                                maxWidth: 650,
                                                                                width: "100%"
                                                                            }}>
                                                                                {accountType === accountTypes.superAdmin ? (
                                                                                    <span className={classes.positionUI}>
                                                                                        {list.position}
                                                                                        <div onClick={() => handleUpdate(list.id, 'position')}>
                                                                                            <CreateOutlinedIcon />
                                                                                        </div>
                                                                                </span>
                                                                                ) : (
                                                                                    <span className={classes.positionUI}>
                                                                                    {list.position}
                                                                                </span>
                                                                                )}
                                                                            </span>

                                                                        </Grid>
                                                                    ) : null
                                                                ))
                                                    }
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    ))}
                            </Grid>
                    }
                </div>
            </Grid>
        </>
    )
}

let mapStateToProps = (state) => {
    return {
        sportPositions: state.sports.sportPositions,
        positionLoading: state.ui.sports.loadingSportsPositions,
        sportType: state.ui.sports.selectedSportsTypeId,
        accountType: state.account.accountType,
        sportId: state.ui.sports.selectedSportId,
        units: state.sports.unitsBySportId,
    };
};

export default connect(mapStateToProps)(UnitDetails);