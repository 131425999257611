import {CircularProgress, Title} from "../../shared";
import {Box, Grid, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import {Link, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import React, {useContext, useEffect} from "react";
import {ActionContext} from "../../../contexts";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import useStyles from './styles';

const CommentSubAttributes = (props) => {
    const classes = useStyles();
    const {assessmentCommentActions, sportsActions} = useContext(ActionContext);
    const location = useLocation();
    const {commentId, loading, subAttributes} = props;

    useEffect(() => {
        assessmentCommentActions.getCommentSubAttributes(commentId);
    }, []);

    const handleSubScoreCreate = () => {
        sportsActions.selectedFormState('create');
        assessmentCommentActions.deselectSubAttribute();
        assessmentCommentActions.getSubAttributeId('');
    };

    const handleSubScoreUpdate = (subAttributeId) => {
        sportsActions.selectedFormState('update');
        assessmentCommentActions.getSubAttributeId(subAttributeId);
        assessmentCommentActions.getSubAttributeById(subAttributeId);
    };

    const splitText = (str) => {
        if (subAttributes.sub_attribute !== '') {
            let splitStr = str?.split('_')
            if (splitStr?.length > 1) {
                return splitStr?.join(' ')
            }
            else {
                return str;
            }
        }
        else {
            return str;
        }
    };

    return (
        <>
            <div className={classes.header}>
                <Title title='Sub Attribute Score' />
                <Link to={`${location.pathname}/create-sub-score`} className={classes.headerBtn}>
                    <div onClick={handleSubScoreCreate}>
                        Create Sub Score
                    </div>
                </Link>
            </div>
            <TableContainer>
                <Grid container style={{padding: '1rem 0px'}}>
                    {
                        loading && subAttributes.length === 0 ?
                            <Box style={{display: 'flex', justifyContent: 'center', margin: 'auto'}}>
                               <CircularProgress size={72} thickness={3}/>
                            </Box> : ( subAttributes && subAttributes.length === 0 ? (
                                <Typography className={classes.noAttributes}>
                                    No Sub Attributes Scores Found
                                </Typography>
                            ) : (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TableHead className={classes.tableHead}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    className={classes.fullName}
                                                    style={{textAlign: 'start'}}
                                                >
                                                    sub attribute
                                                </Grid>
                                                <Grid item xs={3} className={classes.fullName}>
                                                    score start
                                                </Grid>
                                                <Grid item xs={3} className={classes.fullName}>
                                                    score end
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    className={classes.fullName}
                                                    style={{textAlign: 'center'}}
                                                >
                                                    edit
                                                </Grid>
                                            </Grid>
                                        </TableHead>
                                        {
                                            subAttributes && subAttributes.map(item => (
                                                <TableRow className={classes.rowAlignment} key={item._id}>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        className={classes.fullName}
                                                        style={{ textAlign: 'start' }}
                                                    >
                                                        {splitText(item.sub_attribute)}
                                                    </Grid>
                                                    <Grid item xs={3} className={classes.dataTableAlignment}>
                                                        {item.score_start}
                                                    </Grid>
                                                    <Grid item xs={3} className={classes.dataTableAlignment}>
                                                        {item.score_end}
                                                    </Grid>
                                                    <Grid item xs={3} className={classes.dataTableAlignment}>
                                                        <Link to={`${location.pathname}/update-sub-score/${item._id}`}>
                                                            <div onClick={() => handleSubScoreUpdate(item._id)}>
                                                                <CreateOutlinedIcon className={classes.iconColor} />
                                                            </div>
                                                        </Link>
                                                    </Grid>
                                                </TableRow>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            ))
                    }
                </Grid>
            </TableContainer>
        </>
    )
};

let mapStateToProps = (state) => {
    return {
        loading: state.ui.assessmentComments.loadingCommentSubAttributes,
        subAttributes: state.assessmentComments.selectedSubAttributes,
    }
};

export default connect(mapStateToProps)(CommentSubAttributes);